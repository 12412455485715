<template>
  <div>
    <ejs-dialog
        ref="reservationListPopup"
        :header="`타임목록`"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        width="350"
        height="800"
        :animationSettings="{ effect: 'None' }"
        @close="onReservationListPopupClosed"
    >
<!--        :close=""-->
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">타임 목록</div>
                      <div class="header-caption">
                        <!-- 토요일 : saturday / 일요일 : sunday -->
                        [{{ resveDate }}
<!--                        <span class="saturday">{{ timeCount.dwName }}</span> 잔여-->
                        <strong>{{ resvePossibleTime }}</strong
                        >팀]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-check">
                        <li>
                          <label>
                            <input type="checkbox" v-model="smsSendFlag" />
                            <i></i>
                            <div class="label">SMS전송</div>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <ejs-grid-wrapper
                        ref="timeListGrid"
                        :provides="timeListGridOptions.provides"
                        :columns="timeListGridOptions.columns"
                        :allowResizing="true"
                        :editSettings="timeListGridOptions.editSettings"
                        :dataSource="timeList"
                        @headerCellInfo="waitingReservationHeaderCellInfo"
                        @queryCellInfo="timeListQueryCellInfo"
                        @recordClick="timeListGridRecordClicked"
                        @actionComplete="onTimeListGridActionComplete"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onReservationListPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {Edit, ForeignKey, Resize, VirtualScroll} from "@syncfusion/ej2-vue-grids";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx, commonCodesGetCommonCodeAttrbNameByCodeAndIdx, commonCodesGetComName,
  commonCodesGetSortNo, commonCodesGetStandardInfo
} from "@/utils/commonCodes";
import {getReservationWaitTimeList} from "@/api/waitingReservation";
import { orderBy as _orderBy } from "lodash";
import {getDayOfWeekCaption, getTodayNavigationDate} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import moment from "moment/moment";

export default {
  name: "ReservationListPopup",
  mixins: [confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      smsSendFlag: true,
      resveCourseOptions: [],
      timeList: [],
      resvePossibleTime: 0,
      resveDate: null,
      selectData: {}
    };
  },
  mounted() {
    this.resveCourseOptions = commonCodesGetCommonCode(
        "COURSE_CODE"
    );
  },
  computed: {
    timeListGridOptions() {
      return {
        provides: [Edit, VirtualScroll, Resize, ForeignKey],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "resveTime",
            headerText: "시간",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "resveCourse",
            headerText: "코스",
            allowEditing: false,
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.resveCourseOptions,
          },
          {
            field: "resveStatus",
            headerText: "예약자명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            textAlign: "left",
          },
        ],
      };
    },
  },
  methods: {
    async show(selectData) {
      try {
        this.selectData = selectData;
        this.resveDate = selectData.waitingDate;
        const { value: { reservationWaitTimeLists } } = await getReservationWaitTimeList(this.resveDate);
        this.timeList = _orderBy(reservationWaitTimeLists.map(item => ({
          ...item,
          courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.resveCourse),
        }) || []), ["resveDate", "resveTime", "courseCodeSortNo"]);

        this.resvePossibleTime = this.timeList.filter(data => data.timeStatus === "EMPTY" && !data.webOpenFlag).length;

      } catch (e) {
        console.error(e);
      }
    },
    timeListQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data
      } = args;
      if (field === undefined) {
        if (data?.agncyOpenFlag) {
          cell.style.backgroundColor = commonCodesGetColorValue(
              "COLOR_TIME_STATUS",
              "AGNCY_OPEN_FLAG"
          );
        } else if (data.webOpenFlag) {
          cell.style.backgroundColor = commonCodesGetColorValue(
              "COLOR_TIME_STATUS",
              "WEB_OPEN_FLAG"
          );
        }
      } else if (field === "resveTime") {
        cell.classList.add(this.$t("className.grid.selectedCellBold"));
        if (
            data.timeStatus === "BLANK" ||
            data.timeStatus === "BLOCK" ||
            data.timeStatus === "HOLD"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
              "TIME_STATUS",
              data.timeStatus
          );
          cell.style.color = "#FFFFFF";
        } else if (
            data.cancelDiv === "RAIN" ||
            data.cancelDiv === "NOSHOW"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
              "CANCEL_DIV",
              data.cancelDiv
          );
          cell.style.color = "#FFFFFF";
        }
      } else if (field === "resveStatus") {
        cell.classList.add(this.$t("className.grid.clickArea"));
        if (
            !!data.resveId &&
            data.webOpenFlag
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
              "COLOR_TIME_STATUS",
              "WEB_OPEN_FLAG"
          );
        } else if (
            !!data.grpKind &&
            data.grpKind !== "FIT"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
              "GRP_KIND",
              data.grpKind
          );
        } else if (
            data.memberDiv !== null &&
            data.memberDiv !== undefined
        ) {
          // MEMBER_DIV 의 3번째 index Attribute 로 COLOR_MEMBER_DIV의 colorValue 조회 후 백그라운드 등록
          const attrMemberDiv = commonCodesGetCommonCodeAttrbByCodeAndIdx(
              "MEMBER_DIV",
              data.memberDiv,
              3
          );
          cell.style.backgroundColor = commonCodesGetColorValue(
              "COLOR_MEMBER_DIV",
              attrMemberDiv
          );
          cell.style.color = commonCodesGetColorValue(
              "MEMBER_DIV",
              data.memberDiv
          );
        }
      }
    },
    async timeListGridRecordClicked(args) {
      if (args.column && args.column.field === "resveStatus") {
        if (
            args.rowData.timeStatus === "EMPTY" &&
            !args.rowData.webOpenFlag
        ) {
          const today = await getTodayNavigationDate("YYYY-MM-DD");
          const timeData = args.rowData;
          const getRecords = this.selectData;
          if (!getRecords) {
            this.errorToast("예약확정할 대기예약을 선택해 주시기 바랍니다.");
            return;
          }

          if (getRecords.rceptStatus === "RCEPT") {
            // 예약가능 클릭시 해당 타임 홀딩 및 타임 상태 확인을 위함.
            const data = await GolfErpAPI.fetchReservationTimesDetail({
              timeId: timeData.timeId,
            });

            if (data.status !== "OK") {
              if (data.status === "HOLD_TIME_EXIST") {
                this.errorToast("홀딩된 시간입니다.");
              } else if (data.status === "WEB_TIME_EXIST") {
                this.errorToast("웹타임 설정된 시간입니다.");
              }
              return;
            }

            if (
                !(await this.confirm(
                    "예약일자 : " +
                    args.rowData.resveDate +
                    "<br>" +
                    "예약시간 : " +
                    args.rowData.resveTime +
                    " " +
                    commonCodesGetComName(
                        "COURSE_CODE",
                        args.rowData.resveCourse
                    ) +
                    " 코스<br>" +
                    "예약자 : " +
                    getRecords.memberName +
                    "<br><br>" +
                    '"예약확정" 하시겠습니까?'
                ))
            ) {
              // 취소시 해당 타임 홀딩 취소.
              await GolfErpAPI.reservationTimesHoldCancel([timeData.timeId]);
              return;
            }

            const saveData = {
              reservationTimes: [
                {
                  timeId: timeData.timeId,
                  optFlag: timeData.optFlag,
                  timeStatus: timeData.timeStatus,
                  resveDate: timeData.resveDate,
                  resveNo: null,
                  reservationConfirm: {
                    resveId: null,
                    optFlag: null,
                    timeId: timeData.timeId,
                    resveNo: null,
                    visitCnt: 4,
                    promtnId: data?.data?.reservationDetails[0]?.promtnId || null,
                    teamGreenFee: 0,
                    selfFlag: false,
                    joinFlag: false,
                    contactTel: getRecords.contactTel,
                    reservationCmpns: []
                  },
                },
              ],
              reservationMember: {
                resveNo: null,
                optFlag: null,
                resveKind: today === timeData.resveDate ? "DAY" : commonCodeGetComCodeDefaultValue("RESVE_KIND"),
                resveChannel: commonCodeGetComCodeDefaultValue("RESVE_CHANNEL"),
                resveName: getRecords.memberName,
                membershipId: getRecords.membershipId,
                memberNo: getRecords.memberNo,
                memberDiv: getRecords.memberDiv,
                memberGrade: getRecords.memberGrade,
                sexCode: getRecords.sexCode,
                areaCode: getRecords.areaCode,
                contactName: getRecords.memberName,
                contactTel: getRecords.contactTel,
                grpNo: null,
                grpKind: commonCodeGetComCodeDefaultValue("GRP_KIND"),
                grpName: null,
                resveRemarks: null,
                recomendr: null,
                vipFlag: false
              },
              waitingId: getRecords.waitingId,
            };
            // 무인체크인 사용시 예약자를 동반자정보에 셋팅.
            if (commonCodesGetStandardInfo("noneChkinUseFlag")) {
              saveData.reservationTimes[0].reservationConfirm.reservationCmpns.push({
                sno: 1,
                reprsntFlag: true,
                cmpnName: getRecords.memberName,
                joinGroupNo: null,
                contactTel: getRecords.contactTel,
                sexCode: getRecords.sexCode,
                lockerNo: null,
                memberNo: getRecords.memberNo,
                membershipId: getRecords.membershipId,
                memberDiv: getRecords.memberDiv,
                memberGrade: getRecords.memberGrade,
              });
            }
            const newResveNo = await GolfErpAPI.reservationSave(saveData);

            if (this.smsSendFlag) {
              try {
                const smsSendData = {
                  resveNo: newResveNo.resveNo,
                  resveDate: timeData.resveDate,
                  resveCourse: timeData.resveCourse,
                  resveTime: timeData.resveTime,
                  smsRecptnDiv: getRecords.smsRecptnDiv,
                  resveMngrHp: getRecords.resveMngrHp,
                  contactName: getRecords.memberName,
                  memberDiv: getRecords.memberDiv,
                  contactTel: getRecords.contactTel,
                  bsnCode: timeData.bsnCode,
                  selfOpenFlag: timeData.selfOpenFlag,
                };

                await this.autoSendSms(smsSendData);
              } catch (e) {
                this.errorToast("sms 발송에 실패하였습니다");
              }

            }
            this.infoToast("예약이 완료되었습니다");
            this.$emit("popupConfirmed");
          } else {
            this.errorToast("접수등록만 예약확정이 가능합니다");
          }
        }
      }
    },
    waitingReservationHeaderCellInfo(args) {
      if (args.cell.column.headerText === "NO") {
        args.node.classList.add(this.$t("className.grid.noSortFilter"));
      }
      if (args.cell.column.field === "resveStatus") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    async onTimeListGridActionComplete(args) {
      if (args.requestType === "refresh") {
        this.$refs.timeListGrid.filterByColumn(
            "resveStatus",
            "equal",
            "예약가능"
        );
      }
      this.resvePossibleTime = this.$refs.timeListGrid.getCurrentViewRecords().filter(data => data.timeStatus === "EMPTY" && !data.webOpenFlag).length;
    },
    onReservationListPopupClosed() {
      this.$emit("popupClosed");
    },
    async autoSendSms(data) {
      let smsSendList = [];
      let sendData = {};

      sendData.sendKey = data.resveNo;
      sendData.resveDate = data.resveDate;
      sendData.dwName = getDayOfWeekCaption(moment(data.resveDate).toDate());
      sendData.resveCourse = data.resveCourse;
      sendData.resveTime = data.resveTime;
      sendData.transFlag = false;
      sendData.bsnCode = data.bsnCode;
      sendData.caddieSelfDiv = data.selfOpenFlag ? "셀프" : "동반";

      sendData.resveCourseTime =
          commonCodesGetComName("COURSE_CODE", data.resveCourse) +
          " " +
          data.resveTime +
          " (1팀)";

      if (data.smsRecptnDiv === "B") {
        if (data.resveMngrHp) {
          sendData.receiverName = data.contactName;
          sendData.name =
              data.contactName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                  "MEMBER_DIV",
                  data.memberDiv,
                  1
              );
          sendData.contactTel = data.resveMngrHp;
          sendData.smsSendFlag = true;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      } else if (data.smsRecptnDiv === "A") {
        sendData.receiverName = data.contactName;
        sendData.name =
            data.contactName +
            " " +
            commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                data.memberDiv,
                1
            );

        if (data.contactTel) {
          sendData.contactTel = data.contactTel;
          sendData.smsSendFlag = true;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }

        if (data.resveMngrHp) {
          sendData.contactTel = data.resveMngrHp;
          sendData.smsSendFlag = true;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      } else {
        if (data.contactTel) {
          sendData.receiverName = data.contactName;
          sendData.name =
              data.contactName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                  "MEMBER_DIV",
                  data.memberDiv,
                  1
              );
          sendData.contactTel = data.contactTel;
          sendData.smsSendFlag = true;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      }

      if (!(smsSendList.length > 0)) {
        return;
      }

      const smsSendInfo = {
        type: "G_RESVE",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };
      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
          (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
          (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
          (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
            JSON.stringify(message).toString().indexOf("[HEADER_MEMO]$\\n") > 0 ?
                JSON.parse(JSON.stringify(message).replace("[HEADER_MEMO]$\\n", "")) :
                JSON.parse(JSON.stringify(message).replace("[HEADER_MEMO]$", ""));
        message = message + "\n[HEADER_MEMO]$";
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let smsRequestList = [];
      smsSendList.forEach((data) => {
        if (data.smsSendFlag) {
          const weekdayCncde = commonCodesGetStandardInfo("weekdayCncde");
          const weekendCncde = commonCodesGetStandardInfo("weekendCncde");
          const weekdayCnctime = commonCodesGetStandardInfo("weekdayCnctime");
          const weekendCnctime = commonCodesGetStandardInfo("weekendCnctime");

          let smsInfo = {
            type: smsSendInfo.type,
            kakaoFlag: smsSendInfo.kakaoSendFlag,
            smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
            kakaoDispatchKey: smsSendInfo.kakaoSendFlag
                ? smsSendInfo.kakaoDispatchKey
                : null,
            kakaoTemplateKey: smsSendInfo.kakaoSendFlag
                ? smsSendInfo.kakaoTemplateCode
                : null,
            key: data.sendKey,
            reservedFlag: false,
            reserveDateTime: null,
            receiverName: data.receiverName,
            receiverNumber: data.contactTel,
            subject: smsSendInfo.subject,
            message: message,
            replaceDataMap: {
              name: data.name,
              resveDate: data.resveDate,
              dwName: data.dwName,
              resveCourse: data.resveCourse,
              resveTime: `[${data.resveTime}]`,
              resveCourseTime: data.resveCourseTime,
              transResveDiv: data.transFlag === true ? "위임예약" : "예약",
              cancelDate: moment(data.resveDate).add(data.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD"),
              cancelTime: data.bsnCode === "WEEKDAY" ? weekdayCnctime : weekendCnctime,
              cancelDw: getDayOfWeekCaption(moment(data.resveDate).add(data.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD")),
              headerMemo: smsSendInfo.bizName,
              transInfo: "",
              caddieSelfDiv: data.caddieSelfDiv,
            },
            dispatchNumber: smsSendType.dsptchNo,
            sender: "ERP",
          };

          smsRequestList.push(smsInfo);
        }
      });

      await GolfErpAPI.sendSMS(smsRequestList);
    },
  }
};
</script>

<style scoped>

</style>