<template>
  <div>
    <ejs-dialog
      ref="waitingReservationInfoPopup"
      header="대기예약 정보"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="832"
      :close="dialogClose"
      v-on:mousedown.native="onDialogClicked"
      :position="popupPosition"
      :zIndex="currentzIndex"
      :isModal="$props.isModal"
      :visible="isVisible"
      :cssClass="
        reservationWaits.waitingId
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
    >
      <div class="window reservationInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">대기예약 정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">연락자</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field memberNameNumber">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      회원명/번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                              id="memberName"
                                              ref="memberName"
                                              v-model="
                                                reservationWaits.memberName
                                              "
                                              @change="onMemberNameChanged"
                                              placeholder="회원명"
                                              @keydown.enter="
                                                memberNameInputKeyEnter
                                              "
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group text">
                                          {{ reservationWaits.memberNo }}
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                ref="memberPopupOpenButton"
                                                @click.native="
                                                  onMemberPopupOpen(
                                                    true,
                                                    memberPopupType.RESV,
                                                    reservationWaits.memberName,
                                                    null
                                                  )
                                                "
                                              >
                                                조회
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">회원구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="memberDiv"
                                              v-model="
                                                reservationWaits.memberDiv
                                              "
                                              :dataSource="
                                                reservationWaitsOption.memberDivOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="{
                                                text: 'comName',
                                                value: 'comCode',
                                              }"
                                              cssClass="body-data-dropdown"
                                              @change="
                                                onReservationWaitsMemberDivDropDownListChanged
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">회원등급</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="memberGrade"
                                              v-model="
                                                reservationWaits.memberGrade
                                              "
                                              :dataSource="
                                                reservationWaitsOption.memberGradeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="{
                                                text: 'comName',
                                                value: 'comCode',
                                              }"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">연락처</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <component-telephone
                                              id="contactTel"
                                              name="contactTel"
                                              ref="contactTel"
                                              v-model="
                                                reservationWaits.contactTel
                                              "
                                              :max-length="11"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">성별</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="popupSexCodeDropdown"
                                              v-model="
                                                reservationWaits.sexCode
                                              "
                                              :dataSource="
                                                reservationWaitsOption.sexCodeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="{
                                                text: 'comName',
                                                value: 'comCode',
                                              }"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">지역</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="popupAreaCodeDropdown"
                                              v-model="
                                                reservationWaits.areaCode
                                              "
                                              :dataSource="
                                                reservationWaitsOption.areaCodeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="{
                                                text: 'comName',
                                                value: 'comCode',
                                              }"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">대기 신청</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">대기일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                              id="waitingDate"
                                              ref="waitingDate"
                                              v-model="
                                                reservationWaits.waitingDate
                                              "
                                              type="body-data"
                                              format="YYYY-MM-DD"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      희망시간 1
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="hopeTime1"
                                                v-model="
                                                reservationWaits.hopeTime1
                                              "
                                                :dataSource="
                                                reservationWaitsOption.hopeTimeOptions
                                              "
                                                :allowFiltering="false"
                                                :fields="{
                                                text: 'comName',
                                                value: 'comCode',
                                              }"
                                                cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">희망시간 2</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="hopeTime2"
                                                v-model="
                                                reservationWaits.hopeTime2
                                              "
                                                :dataSource="
                                                reservationWaitsOption.hopeTimeOptions
                                              "
                                                :allowFiltering="false"
                                                :fields="{
                                                text: 'comName',
                                                value: 'comCode',
                                              }"
                                                cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">희망코스</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="popupHopeCourseDropdown"
                                              v-model="
                                                reservationWaits.hopeCourse
                                              "
                                              :dataSource="
                                                reservationWaitsOption.hopeCourseOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="{
                                                text: 'comName',
                                                value: 'comCode',
                                              }"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">희망홀 구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="popupHopeHoleDivDropdown"
                                              v-model="
                                                reservationWaits.hopeHoleDiv
                                              "
                                              :dataSource="
                                                reservationWaitsOption.hopeHoleDivOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="{
                                                text: 'comName',
                                                value: 'comCode',
                                              }"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">희망팀수</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              v-model="
                                                reservationWaits.hopeTeamCnt
                                              "
                                              v-on:keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">셀프라운드</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="selfFlagTrue"
                                                  v-model="
                                                    reservationWaits.selfFlag
                                                  "
                                                  value="true"
                                                />
                                                <i></i>
                                                <div class="label">
                                                  노캐디
                                                </div>
                                              </label>
                                            </li>
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="selfFlagFalse"
                                                  v-model="
                                                    reservationWaits.selfFlag
                                                  "
                                                  value="false"
                                                />
                                                <i></i>
                                                <div class="label">캐디</div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">조인 신청</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="joinReqstFlagTrue"
                                                  v-model="
                                                    reservationWaits.joinReqstFlag
                                                  "
                                                  value="true"
                                                />
                                                <i></i>
                                                <div class="label">예</div>
                                              </label>
                                            </li>
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="joinReqstFlagFalse"
                                                  v-model="
                                                    reservationWaits.joinReqstFlag
                                                  "
                                                  value="false"
                                                />
                                                <i></i>
                                                <div class="label">
                                                  아니오
                                                </div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field requestContent">
                                    <!-- 필수 : required -->
                                    <div class="title">요청 내용</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                              v-model="
                                                reservationWaits.requstContents
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field requestContent">
                                    <!-- 필수 : required -->
                                    <div class="title">메모</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                              v-model="
                                                reservationWaits.memoContents
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0103">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">접수상태</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">접수상태</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <!-- <div class="form">
                                            <ejs-dropdownlist ref='popupRceptStatusDropdown' v-model='reservationWaits.rceptStatus' :dataSource='reservationWaitsOption.rceptStatusOptions'
                                                              :enabled='false' :allowFiltering='false' :fields="{ text: 'comName', value: 'comCode' }" cssClass='body-data-dropdown'></ejs-dropdownlist>
                                          </div> -->
                                          {{
                                            commonCodesGetComName(
                                              "RCEPT_STATUS",
                                              reservationWaits.rceptStatus
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">확정시간</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ reservationWaits.resveTime }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">확정코스</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{
                                            commonCodesGetComName(
                                              "COURSE_CODE",
                                              reservationWaits.resveCourse
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0104">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">접수자</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">접수자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ reservationWaits.insertName }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">접수일시</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ reservationWaits.insertDt }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  v-on:click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="waitingReservationInfoPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
  </div>
</template>

<script>
import {
  commonCodesGetCommonCode,
  commonCodesGetJsonData,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import {
  formPreventEnterEvent,
  validateFormRefs,
} from "@/utils/formUtil";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {
  getWaitingReservation,
  saveWaitingReservation,
  getReservationWaitCount,
} from "@/api/waitingReservation";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import InputDate from "@/components/common/datetime/InputDate";
import InputNumber from "@/components/common/InputNumber";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "waitingReservationPopup",
  components: {
    ComponentTelephone,
    InputTextarea,
    InputText,
    InputNumber,
    memberSelectPopup,
    InputDate,
    ErpButton,
  },
  props: ["popupKey", "value", "position", "zIndex", "isModal", "isVisible"],
  mixins: [commonMixin, confirmDialogMixin],
  created: function () {
    // productCode 값 설정
    this.reservationWaits.waitingId = this.$props.popupKey;
  },
  data: function () {
    return {
      memberPopupType: {
        RESV: "RESV", // 예약자
        TRANS: "TRANS", // 위임자
        COMP: "COMP", // 동반자
      },
      isMemberSelectPopupOpen: false,
      animationSettings: { effect: "None" },
      popupPosition: { X: this.$props.position.x, Y: this.$props.position.y },
      currentzIndex: this.$props.zIndex,
      reservationWaits: {
        waitingId: null,
        memberName: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        contactTel: "",
        sexCode: null,
        areaCode: null,
        waitingDate: null,
        hopeTime1: null,
        hopeTime2: null,
        hopeCourse: null,
        hopeHoleDiv: "18",
        hopeTeamCnt: 1,
        selfFlag: false,
        joinReqstFlag: false,
        requstContents: null,
        memoContents: null,
        rceptStatus: "RCEPT",
        rceptStatusName: "접수등록",
        resveTime: null,
        resveCourse: null,
        resveCourseName: null,
        insertId: null,
        insertDt: null,
      },
      reservationWaitsOption: {
        hopeTimeOptions: [],
        resveCourseOptions: [],
        memberDivOptions: [],
        memberGradeOptions: [],
        sexCodeOptions: [],
        areaCodeOptions: [],
        hopeCourseOptions: [],
        hopeHoleDivOptions: [],
        rceptStatusOptions: [],
      },
      validateRefList: {
        memberName: {
          required: true,
        },
        memberDiv: {
          required: true,
        },
        memberGrade: {
          required: true,
        },
        waitingDate: {
          required: true,
        },
        hopeTime1: {
          required: true,
        },
        contactTel: {
          required: true,
        },
      },
    };
  },
  mounted: function () {
    this.reservationWaitsOption.hopeTimeOptions = commonCodesGetCommonCode(
        "HOPE_TIME_ZONE"
    );
    this.reservationWaitsOption.resveCourseOptions = commonCodesGetCommonCode(
      "COURSE_CODE"
    );
    this.reservationWaitsOption.memberDivOptions = commonCodesGetCommonCode(
      "MEMBER_DIV"
    );
    this.reservationWaitsOption.memberGradeOptions = commonCodesGetCommonCode(
      "MEMBER_GRADE"
    );
    this.reservationWaitsOption.sexCodeOptions = commonCodesGetCommonCode(
      "SEX_CODE"
    );
    this.reservationWaitsOption.areaCodeOptions = commonCodesGetCommonCode(
      "AREA_CODE"
    );
    this.reservationWaitsOption.hopeCourseOptions = commonCodesGetCommonCode(
      "COURSE_CODE"
    );
    this.reservationWaitsOption.hopeHoleDivOptions = commonCodesGetCommonCode(
      "HOLE_DIV"
    );
    this.reservationWaitsOption.rceptStatusOptions = commonCodesGetCommonCode(
      "RCEPT_STATUS"
    );

    this.reservationWaits.memberDiv = "NOM";

    let memberGradeDefaultOption = this.reservationWaitsOption.memberGradeOptions.find(
      (options) => options.defaultFlag === true
    );
    if (memberGradeDefaultOption) {
      this.reservationWaits.memberGrade = memberGradeDefaultOption.comCode;
    }

    let sexCodeDefaultOption = this.reservationWaitsOption.sexCodeOptions.find(
      (options) => options.defaultFlag === true
    );
    if (sexCodeDefaultOption) {
      this.reservationWaits.sexCode = sexCodeDefaultOption.comCode;
    }

    if (
      this.reservationWaitsOption.memberDivOptions.findIndex(
        (obj) => obj.comCode === null
      ) === -1
    ) {
      this.reservationWaitsOption.memberDivOptions.unshift({
        comCode: null,
        comName: "",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    if (
      this.reservationWaitsOption.memberGradeOptions.findIndex(
        (obj) => obj.comCode === null
      ) === -1
    ) {
      this.reservationWaitsOption.memberGradeOptions.unshift({
        comCode: null,
        comName: "",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    if (
      this.reservationWaitsOption.hopeCourseOptions.findIndex(
        (obj) => obj.comCode === null
      ) === -1
    ) {
      this.reservationWaitsOption.hopeCourseOptions.unshift({
        comCode: null,
        comName: "",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.loadData();
  },
  methods: {
    commonCodesGetComName,
    validateFormRefs,
    loadData() {
      if (this.reservationWaits.waitingId) {
        this.getWaitingReservation(this.reservationWaits.waitingId);
      }
    },
    setZIndex: function (zIndex) {
      this.currentzIndex = zIndex;
    },
    preventEnterEvent: formPreventEnterEvent,
    showPopup(waitingId) {
      if (waitingId) {
        this.reservationWaits.waitingId = waitingId;
        this.loadData();
      }
      this.$refs.waitingReservationInfoPopup.show();
    },
    dialogClose: function () {
      this.$emit(
        "popupEvent",
        "popupClosed",
        this.reservationWaits.waitingId,
        this.$props.value
      );
    },
    onDialogClicked: function () {
      this.$emit(
        "popupEvent",
        "popupClicked",
        this.$props.popupKey,
        this.$props.value
      );
    },
    onSaveButtonClicked() {
      if (
        this.reservationWaits.rceptStatus === "RESVE" ||
        this.reservationWaits.rceptStatus === "RESVE_CANCEL" ||
        this.reservationWaits.rceptStatus === "RCEPT_CANCEL"
      ) {
        this.errorToast(
          "접수상태가 접수취소/예약확정/예약취소는 데이터를 변경하실 수 없습니다"
        );
        return;
      }
      const validateRtn = this.validate();

      if (validateRtn !== true) {
        return;
      }

      this.getReservationWaitCount(this.reservationWaits);
      // this.saveWaitingReservation(this.reservationWaits)
    },
    waitingReservationInfoPopupClose() {
      this.$refs.waitingReservationInfoPopup.hide();
    },
    onMemberPopupOpen: function (
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx,
      memberDivChanged = false,
      memberGradeChanged = false
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberDivChanged = memberDivChanged;
      memberData.memberGradeChanged = memberGradeChanged;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed: function (data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.popupData.memberDivChanged) {
          let commonCodeJsonDataStr = this.reservationWaitsOption.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode === this.reservationWaits.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                // 회원구분 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
                // 회원구분 변경한 값은 그대로 유지.
                // MEMBERSHIP_ID, MEMBER_NO = 기존값 그대로 유지.
                // MEMBER_DIV = 변경한 값
                // MEMBER_GRADE = 회원구분 변경에 따른 값 셋팅.
                this.reservationWaits.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else if (data.popupData.memberGradeChanged) {
          // 회원등급 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
          // 회원등급 변경한 값은 그대로 유지.
          // MEMBERSHIP_ID, MEMBER_NO, MEMBER_DIV = 기존값 그대로 유지.
          // MEMBER_GRADE = 변경한 값
        } else {
          if (!data.popupData.isOpenedWithButton) {
            // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
            this.setResvNonMemberInfos();
          }
        }
      }
    },
    memberSelectPopupConfirmed: function (data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.selectedRowData) {
          if (data.selectedRowData.memberDiv == "NOM") {
            this.setResvNonMemberInfos(data.selectedRowData);
          } else {
            this.setResvMemberInfos(data.selectedRowData);
          }
          // this.reNewValidateMessage() // validator message 갱신
          // this.reservationCheck(null, null, data.selectedRowData.membershipId) // 위약 체크 // 대기예약은 예약 확정시 위약 체크
        }
      }
    },
    setResvMemberInfos: function (memberInfo) {
      if (this.reservationWaits) {
        this.reservationWaits.memberName = memberInfo.memberName;
        this.reservationWaits.membershipId = memberInfo.membershipId;
        this.reservationWaits.memberNo = memberInfo.memberNo;
        this.reservationWaits.memberDiv = memberInfo.memberDiv;
        this.reservationWaits.memberGrade = memberInfo.memberGrade;
        this.reservationWaits.sexCode = memberInfo.sexCode;
        this.reservationWaits.contactTel = memberInfo.hpNo;
        this.reservationWaits.areaCode = memberInfo.areaCode;
      }
    },
    setResvNonMemberInfos: function (nomMember = null) {
      if (this.reservationWaits) {
        this.reservationWaits.memberName = nomMember.memberName || "";
        this.reservationWaits.membershipId = "";
        this.reservationWaits.memberNo = "";
        this.reservationWaits.sexCode = "M";
        this.reservationWaits.memberDiv = "NOM";
        this.reservationWaits.memberGrade = commonCodesGetJsonData(
          "MEMBER_DIV",
          "NOM"
        ).find((codeJson) => codeJson.IDX === 4).ATTRB;
        this.reservationWaits.contactTel = "";
        this.reservationWaits.areaCode = null;
      }
    },
    // reNewValidateMessage: function () {
    //   if (this.reservationInfoForm) {
    //     setTimeout(() => { // validator 메시지가 갱신되지 않은 경우가 있으므로 validate()를 호출하여 갱신함. $nextTick() 이 먹지 않아서, 불가피하게 timeout을 사용함
    //       this.reservationInfoForm.validate()
    //     }, 50)
    //   }
    // },
    reservationCheck: function (contactTel, grpNo, membershipId) {
      // 위약 체크
      // TODO : 예약 가능 체크 API 조회
      const reservationCheckResponse = this.getReservationCheckAPI(
        contactTel,
        grpNo,
        membershipId
      );

      // 위약 체크 팝업
      if (reservationCheckResponse && reservationCheckResponse.penaltyMessage) {
        if (confirm(reservationCheckResponse.penaltyMessage) === true) {
          alert("개발중:: 위약/블랙리스트 조회 팝업");
        }
      }

      // 예약 가능 체크 팝업
      if (reservationCheckResponse && reservationCheckResponse.resveMessage) {
        alert(reservationCheckResponse.resveMessage);
      }
    },
    onMemberNameChanged(args) {
      if (args.event && !!args.value) {
        // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
        // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

        // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
        // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
        // #2. 회원/비회원 위임자의 경우
        // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명을 예약자명과 동일하게 설정
        // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
        //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
        //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
        let searchConditions = {};
        searchConditions.memberNameNo = args.value;

        // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
        this.onMemberPopupOpen(
          false,
          this.memberPopupType.RESV,
          args.value,
          null
        );
      }
    },
    getReservationCheckAPI: function (contactTel, grpNo, membershipId) {
      console.log(
        `contactTel: ${contactTel}, grpNo: ${grpNo}, membershipId:${membershipId}`
      );
      return {
        penaltyMessage:
          "√위약으로 인하여 예약정지 상태입니다. 예약정지기간 : 20190101 ~ 20190431\r\r확인해 주십시오.",
        resveMessage: "√동일한 일자에 예약된 타임이 있습니다",
      };
    },
    getWaitingReservation(waitingId) {
      const bsnCode = null;
      const memberName = null;
      const memberNameNumber = null;
      const memberNumber = null;
      const rceptStatus = null;
      const waitingDateFrom = null;
      const waitingDateTo = null;
      getWaitingReservation(
        waitingId,
        bsnCode,
        memberName,
        memberNameNumber,
        memberNumber,
        rceptStatus,
        waitingDateFrom,
        waitingDateTo
      )
        .then((response) => {
          this.reservationWaits = response.value.reservationWaits[0];
          if (this.reservationWaits.requstContents === undefined) {
            this.reservationWaits.requstContents = null;
          }
          if (this.reservationWaits.memoContents === undefined) {
            this.reservationWaits.memoContents = null;
          }
        })
        .catch((error) => {
          console.log("getTimeTable.err.===>", error);
        });
    },
    getReservationWaitCount(data) {
      const waitingDate = data.waitingDate;
      const memberName = data.memberName;
      getReservationWaitCount(waitingDate, memberName)
        .then(async (response) => {
          if (
            this.reservationWaits.waitingId === null ||
            this.reservationWaits.waitingId === ""
          ) {
            if (response.value > 0) {
              if (
                !(await this.confirm(
                  waitingDate +
                    " " +
                    memberName +
                    "님(접수등록)은 이미 대기예약 등록되어있습니다.\n\n추가 등록하시겠습니까?"
                ))
              ) {
                return;
              }
            }
          }
          this.saveWaitingReservation(data);
        })
        .catch((error) => {
          console.log("getReservationWaitCount.err.===>", error);
        });
    },
    saveWaitingReservation(data) {
      saveWaitingReservation(data)
        .then((response) => {
          if (response.value != null) {
            this.reservationWaits.waitingId = response.value;
          }

          this.infoToast(this.$t("main.popupMessage.saved"));
        })
        .catch((error) => {
          console.log("saveWaitingReservation.err.===>", error);
        });
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
    memberNameInputKeyEnter() {
      this.$refs.memberPopupOpenButton.focus();
    },
    onReservationWaitsMemberDivDropDownListChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        if (args.value === "NOM") {
          let commonCodeJsonDataStr = this.reservationWaitsOption.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode === this.reservationWaits.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                this.reservationWaits.membershipId = "";
                this.reservationWaits.memberNo = "";
                this.reservationWaits.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else {
          // 회원구분이 비회원이 아니면 동명이인 회원 검색 팝업 호출.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.RESV,
            this.reservationWaits.memberName,
            null,
            null,
            true
          );
        }
      }
    },
  },
};
</script>
