<template>
  <div id="holll">
    <ejs-dialog
      ref="wordsPopup"
      :header="`문구 관리`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onPopupClosed"
    >
      <div class="window stationeryManagement">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field division">
                    <div class="title">구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          v-model="wordsDiv"
                          :dataSource="commonCodesGetCommonCode('WORDS_DIV')"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          cssClass="lookup-condition-dropdown"
                          @change="wordsDivChanged"
                        ></ejs-dropdownlist>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">문구 관리</div>
                    </div>
                    <div class="header-right">
                      <div class="header-button">
                        <li class="add">
                          <erp-button
                            button-div="SAVE"
                            :is-custom-shortcut-button="true"
                            :shortcut="{key: 'F3'}"
                            shortcut-key="wordsPopup.shortcuts.add"
                            v-on:click.native="addWord"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                            button-div="DELETE"
                            :is-shortcut-button="true"
                            v-on:click.native="deleteWord"
                          >
                            삭제
                          </erp-button>
                        </li>
                        <li class="reset">
                          <erp-button
                            button-div="GET"
                            @click.native="$refs.wordsGrid.batchCancel()"
                          >
                            초기화
                          </erp-button>
                        </li>
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="wordsGrid"
                        :provides="gridProvides"
                        :dataSource="wordsList"
                        :columns="columns"
                        @queryCellInfo="gridCustomiseCell"
                      >
                      </ejs-grid-wrapper>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                v-on:click.native="saveWords()"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" v-on:click.native="$refs.wordsPopup.hide()">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit, Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";

import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeByAttrb,
} from "@/utils/commonCodes";
import { getWords, saveWords } from "@/api/common";

import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "WordsPopup",
  mixins: [commonMixin, confirmDialogMixin],
  props: [],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      columns: [
        {
          field: "wordsId",
          allowEditing: false,
          visible: false,
          type: "string",
        },
        {
          field: "words",
          headerText: "메모내용",
          minWidth: 16,
          width: 480,
          type: "string",
          textAlign: "Left",
        },
      ],
      gridProvides: [Edit, Resize, ForeignKey],
      popupKindConst: {
        RESERVATION_CANCEL: 0,
        SEND_SMS: 1,
      },
      popupKind: null,
      wordsDiv: null,
      wordsList: [],
      cancelInfo: {
        resveName: null,
        resveSmsSendFlag: true,
        resveContactTel: null,
        cancelResn: null,
      },
      cancelTimeList: [],
    };
  },
  mounted: function () {},
  methods: {
    commonCodesGetCommonCode,
    commonCodesGetCommonCodeByAttrb,
    showWordsPopup: function (popupKind) {
      this.popupKind = popupKind;

      // 골프예약취소 초기값 설정
      if (this.popupKind === this.popupKindConst.RESERVATION_CANCEL) {
        this.wordsDiv = "G_CANCEL";
      } else if (this.popupKind === this.popupKindConst.SEND_SMS) {
        this.wordsDiv = "SMS";
      }
    },
    onPopupClosed: function () {
      this.$emit("popupClosed");
    },
    getWords: function () {
      getWords(this.wordsDiv)
        .then((response) => {
          this.wordsList = response.value.wordsList;
        })
        .catch((error) => {
          console.log("getWords.err.===>", error);
        });
    },
    addWord() {
      this.$refs.wordsGrid.addRecord();
    },
    deleteWord() {
      this.$refs.wordsGrid.deleteRecord();
    },
    saveWords: async function () {
      if (await this.confirm("저장하시겠습니까?")) {
        let saveWordsObj = JSON.parse(
          JSON.stringify(this.$refs.wordsGrid.getBatchChanges())
        );
        saveWordsObj.wordsDiv = this.wordsDiv;
        saveWords(saveWordsObj)
          .then(() => {
            this.getWords();
          })
          .catch((error) => {
            console.log("saveWords.err.===>", error);
          });
      }
    },
    gridCustomiseCell: function (args) {
      // 수정영역 셀 처리
      if (args.column.field === "check" || args.column.field === "words") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    wordsDivChanged: function (args) {
      this.wordsDiv = args.value;
      this.getWords();
    },
  },
};
</script>
