<template>
  <div id="holll">
    <ejs-dialog
      ref="cancelPopup"
      :header="`예약 취소`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1180"
      :isModal="true"
      :close="onPopupClosed"
    >
      <div class="window cancelReservation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예약 취소정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field reservationName">
                              <!-- 필수 : required -->
                              <div class="title">예약자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        v-model="cancelInfo.resveName"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li style="width: 50px;">
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="resveSmsSendFlag"
                                            v-model="cancelInfo.resveSmsSendFlag"
                                            value="true"
                                            @change="onResveSmsSendFlagChanged"
                                          />
                                          <i></i>
                                          <div class="label">SMS</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="isPackageCancelSms"
                                            v-model="cancelInfo.isPackageCancelSms"
                                            :disabled="isPackageCancelSmsByDisabled || !cancelInfo.resveSmsSendFlag"
                                            value="true"
                                          />
                                          <i></i>
                                          <div class="label">패키지 예약 취소 SMS</div>
                                        </label>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">연락처</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                        v-model="cancelInfo.resveContactTel"
                                        :max-length="11"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예약 취소타임</div>
                      <div class="header-caption" style="color: red;">※ 월 누적 취소 횟수 : {{cancelTimeList[0].cancelCountByMonth}}회</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="reset">
                          <erp-button
                            button-div="GET"
                            @click.native="$refs.cancelGrid.batchCancel()"
                          >
                            초기화
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="cancelGrid"
                      :provides="cancelGridOptions.provides"
                      :columns="cancelGridOptions.columns"
                      :dataSource="cancelTimeList"
                      :editSettings="cancelGridOptions.editSettings"
                      @queryCellInfo="gridCustomiseCell"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-03">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">취소사유</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data body">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field reservationName">
                              <div class="title" style="display: none">
                                취소사유
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        id="cancelResn"
                                        ref="cancelResn"
                                        v-model="cancelInfo.cancelResn"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="cancel">
              <erp-button
                button-div="DELETE"
                :is-shortcut-button="true"
                :ignore="isPopupOpened"
                v-on:click.native="cancelReservation()"
              >
                예약취소
              </erp-button>
            </li>
            <li>
              <erp-button
                button-div="SAVE"
                v-on:click.native="manageLetter()"
              >
                문구관리
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" v-on:click.native="$refs.cancelPopup.hide()">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <words-popup
      v-if="isWordsPopupOpen"
      ref="wordsPopup"
      @popupClosed="onWordsPopupClosed"
    />
  </div>
</template>

<script>
import Vue from "vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import moment from "moment";
import { orderBy as _orderBy, groupBy as _groupBy, minBy as _minBy, maxBy as _maxBy } from "lodash";

import { Edit, Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";

import {
  commonCodesGetStandardInfo,
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetCommonCodeByAttrb,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetCommonCodeByIdxAttrb, commonCodesGetSortNo
} from "@/utils/commonCodes";
import {getDayOfWeekCaption, getFormattedDate} from "@/utils/date";
import { validateFormRefs } from "@/utils/formUtil";

import wordsPopup from "./WordsPopup";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "ReservationCancelPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ComponentTelephone,
    InputTextarea,
    InputText,
    EjsGridWrapper,
    wordsPopup,
    ErpButton,
  },
  data: function () {
    return {
      reservationCancelValidationRules: {
        cancelResn: {
          required: true,
        },
      },
      popupData: {},
      isPackageCancelSmsByDisabled: false,
      cancelInfo: {
        resveDate: null,
        resveCourse: null,
        resveTime: null,
        resveNo: null,
        resveName: null,
        resveSmsSendFlag: true,
        isPackageCancelSms: false,
        resveContactTel: null,
        resveMemberDiv: null,
        resveMngrHp: null,
        resveMngrName: null,
        smsRecptnDiv: null,
        cancelResn: ".",
        roomStayRecords: [],
      },
      reservationCourseList: [],
      cancelTimeList: [],
      numberTemplate: function () {
        // TODO : 공통 모듈로??
        return {
          template: Vue.component("editOption", {
            template: "<span>{{parseInt(data.index) + 1}}</span>",
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
      penltyApplyFlag: false,
      isWordsPopupOpen: false,
    };
  },
  computed: {
    isPopupOpened() {
      return this.isWordsPopupOpen;
    },
    cancelGridOptions() {
      return {
        provides: [Edit, Resize, ForeignKey],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "timeId",
            allowEditing: false,
            type: "string",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "resveDate",
            headerText: "예약일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "resveTime",
            headerText: "시간",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "left",
          },
          {
            field: "resveCourse",
            headerText: "코스",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "COURSE_CODE",
          },
          {
            field: "holeDiv",
            headerText: "홀구분",
            allowEditing: false,
            minWidth: 16,
            width: 60,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "HOLE_DIV",
          },
          {
            field: "cancelChannel",
            headerText: "취소채널",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "RESVE_CHANNEL",
          },
          {
            field: "penltyCode",
            headerText: "위약구분",
            allowEditing: true,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            editType: "dropdownedit",
            isCommonCodeField: true,
            dataSource: commonCodesGetCommonCodeByIdxAttrb("PENLTY_CODE", 2, "Y"),
            edit: { params: { sortOrder: "none" } },
          },
          {
            field: "penltyApplyFlag",
            headerText: "적용",
            allowEditing: true,
            type: "boolean",
            minWidth: 16,
            width: 60,
            textAlign: "center",
            editType: "booleanedit",
            displayAsCheckBox: true,
          },
          {
            field: "transName",
            headerText: "위임자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "smsSendFlag",
            headerText: "SMS",
            allowEditing: true,
            type: "boolean",
            minWidth: 16,
            width: 60,
            textAlign: "center",
            editType: "booleanedit",
            displayAsCheckBox: true,
          },
          {
            field: "transContactTel",
            headerText: "연락처",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "left",
          },
          {
            field: "resveRtrvlMethod",
            headerText: "회수방법",
            allowEditing: true,
            minWidth: 16,
            width: 120,
            textAlign: "left",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "RESVE_RTRVL_METHOD",
            edit: { params: { sortOrder: "none" } },
          },
        ],
      };
    },
  },
  mounted: function () {},
  methods: {
    validateFormRefs,
    commonCodesGetCommonCode: commonCodesGetCommonCode,
    commonCodesGetCommonCodeByAttrb: commonCodesGetCommonCodeByAttrb,
    openPopup: async function (popupData) {
      this.popupData = popupData;
      this.cancelInfo.resveDate = popupData.resveDate;
      this.cancelInfo.resveCourse = popupData.resveCourse;
      this.cancelInfo.resveTime = popupData.resveTime;
      this.cancelInfo.resveNo = popupData.resveNo;
      this.cancelInfo.resveName = popupData.resveName;
      this.cancelInfo.resveContactTel = popupData.resveContactTel;
      this.cancelInfo.resveMemberDiv = popupData.resveMemberDiv;
      this.cancelInfo.resveMngrHp = popupData.resveMngrHp;
      this.cancelInfo.resveMngrName = popupData.resveMngrName;
      this.cancelInfo.smsRecptnDiv = popupData.smsRecptnDiv;
      this.cancelInfo.roomStayRecords = popupData.roomStayRecords || [];
      this.reservationCourseList = popupData.reservationCourseList;

      if (this.popupData.timeIds && this.popupData.timeIds.length > 0) {
        const reservationTimes = await GolfErpAPI.fetchReservationTimesByTimeIds(
          this.popupData.timeIds
        );

        this.cancelTimeList = _orderBy(reservationTimes.map((reservationTime) => ({
          ...reservationTime,
          joinFlag: reservationTime.reservationConfirm
            ? reservationTime.reservationConfirm.joinFlag
            : null,
          memberDiv:
            !!reservationTime.reservationConfirm &&
            !!reservationTime.reservationConfirm.reservationMember
              ? reservationTime.reservationConfirm.reservationMember.memberDiv
              : null,
          /* API 공통사용에 따른 영향도 때문에 현 화면에서만 penltyApplyFlag :false로 처리한다.
          penltyApplyFlag: reservationTime.defaultPenltyApplyFlag
            ? reservationTime.defaultPenltyApplyFlag
            : false,
          penltyCode: reservationTime.defaultPenltyCode
            ? reservationTime.defaultPenltyCode
            : "DOK",
           */
          penltyApplyFlag: false,
          penltyCode: "DOK",

          penltyFlag: commonCodesGetStandardInfo("penltyUse"),
          cancelChannel: "TEL",
          resveId: reservationTime.reservationConfirm
            ? reservationTime.reservationConfirm.resveId
            : null,
          resveNo: reservationTime.reservationConfirm
            ? reservationTime.reservationConfirm.resveNo
            : null,
          smsSendFlag: false,
          transContactTel:
            !!reservationTime.reservationConfirm &&
            !!reservationTime.reservationConfirm.reservationTrans
              ? reservationTime.reservationConfirm.reservationTrans.contactTel
              : null,
          transName:
            !!reservationTime.reservationConfirm &&
            !!reservationTime.reservationConfirm.reservationTrans
              ? reservationTime.reservationConfirm.reservationTrans.transName
              : null,
          transMemberDiv:
            !!reservationTime.reservationConfirm &&
            !!reservationTime.reservationConfirm.reservationTrans
              ? reservationTime.reservationConfirm.reservationTrans.memberDiv
              : null,
          courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", reservationTime.resveCourse),
        })), ["resveTime", "courseCodeSortNo"]);
      }

      this.isPackageCancelSmsByDisabled =
        !(this.cancelInfo.roomStayRecords.length > 0 &&
        this.cancelInfo.resveSmsSendFlag &&
        Object.keys(_groupBy(this.cancelTimeList.map(item => item.resveDate))).length > 1)
      ;

      this.cancelInfo.isPackageCancelSms = JSON.parse(JSON.stringify(!this.isPackageCancelSmsByDisabled));
    },
    wordsPopupOpen: function () {
      this.isWordsPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.wordsPopup.showWordsPopup(
          this.$refs.wordsPopup.popupKindConst.RESERVATION_CANCEL
        );
      });
    },
    onWordsPopupClosed: function () {
      this.isWordsPopupOpen = false;
    },
    onPopupClosed: function () {
      this.$emit("popupClosed");
    },
    cancelReservation: async function () {
      const cancelReservationMessage = (
          this.cancelTimeList[0].cancellableCountByMonth !== undefined
          && this.cancelTimeList[0].cancelCountByMonth >= this.cancelTimeList[0].cancellableCountByMonth
          ? "예약 취소 가능 횟수를 초과하였습니다. 예약을 취소하시겠습니까?"
          : "예약을 취소하시겠습니까?");

      if (!(await this.confirm(cancelReservationMessage))) {
        return;
      }

      if (!this.validateFormRefs(this.reservationCancelValidationRules)) {
        return;
      }

      const cancelGridData = this.$refs.cancelGrid.getBatchCurrentViewRecords();

      if (cancelGridData.filter(data => data?.reservationConfirm?.resveStatus !== "RESVE").length > 0) {
        this.infoToast("취소 가능한 예약상태가 아닙니다");
        return;
      }

      let reservationCancelTimes = [];

      cancelGridData.forEach((data) => {
        const cancelTime = {
          timeId: data.timeId,
          optFlag: data.optFlag,
          resveDate: data.resveDate,
          resveRtrvlMethod: data.resveRtrvlMethod,
          reservationConfirm: {
            resveId: data.resveId,
            optFlag: data.reservationConfirm.optFlag,
            penltyApplyFlag: data.penltyApplyFlag,
            penltyCode: data.penltyCode,
            cancelResn: this.cancelInfo.cancelResn,
            reservationMember: {
              resveNo: data.reservationConfirm.reservationMember.resveNo,
              optFlag: data.reservationConfirm.reservationMember.optFlag,
            },
          },
        };

        reservationCancelTimes.push(cancelTime);
      });

      await GolfErpAPI.reservationTimeCancel(reservationCancelTimes);

      // 예약 취소후 SMS 자동 전송.
      if (this.cancelInfo.resveSmsSendFlag) {
        await this.autoSendSms();
      }

      this.$emit("popupClosed", {
        isCanceled: true,
        popupData: this.popupData,
      });

      // if (await this.confirm('예약을 취소하시겠습니까?')) {
      //   if (!this.validateFormRefs(this.reservationCancelValidationRules)) {
      //     return;
      //   }
      //
      //   // 저장된 값을 보내야 하므로 grid save 처리
      //   // this.$refs.cancelGrid.batchSave();
      //   const cancelGridData = this.$refs.cancelGrid.getBatchCurrentViewRecords();
      //
      //   // 취소 API 호출
      //   this.cancelInfo.reservationTimeLists = cancelGridData;
      //
      //   reservationCancel(this.cancelInfo).then(response => {
      //     // 예약 취소후 SMS 자동 전송.
      //     this.autoSendSms();
      //
      //     this.$emit('popupClosed', { isCanceled: true, popupData: this.popupData });
      //   }).catch(error => {
      //     console.log('reservationCancel.err.===>', error);
      //   });
      // }
    },
    async autoSendSms() {
      let smsSendList = [];
      let sendData = {};

      let resveDateArr = [];
      let dwnameArr = [];
      _orderBy(this.cancelTimeList, ["resveDate", "resveTime"], ["asc", "asc"]).forEach(data => {
        if (!resveDateArr.includes(data.resveDate)) {
          resveDateArr.push(data.resveDate);
          dwnameArr.push(getDayOfWeekCaption(moment(data.resveDate).toDate()));
        }
      });

      sendData.sendKey = this.cancelInfo.resveNo;
      sendData.resveDate = resveDateArr.join(", ");
      sendData.dwName = dwnameArr.join(", ");
      sendData.resveCourse = commonCodesGetComName(
        "COURSE_CODE",
        this.cancelInfo.resveCourse
      );
      sendData.resveTime = this.cancelTimeList.map(item => `[${item.resveTime}]`).join(" ");
      sendData.transFlag = false;
      if (
        this.cancelTimeList.length === 1 &&
        this.cancelTimeList[0].transName !== null &&
        this.cancelTimeList[0].transName !== undefined &&
        this.cancelTimeList[0].transName.trim() !== ""
      ) {
        sendData.transInfo = `◎ 위임자 : ${this.cancelTimeList[0].transName}`;
        sendData.transname = this.cancelTimeList[0].transName +
          " " +
          commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
            "MEMBER_DIV",
            this.cancelTimeList[0].transMemberDiv,
            1
          );
      }
      sendData.resveCourseTime = `${this.reservationCourseList
        .map(({ resveCourseCode, resveCourseName }) => ({
          resveCourseName,
          reservationTimeList: this.cancelTimeList.filter(
            (timeInfo) => timeInfo.resveCourse === resveCourseCode
          ),
        }))
        .filter(
          ({ reservationTimeList = [] }) => reservationTimeList.length !== 0
        )
        .map(
          ({ resveCourseName, reservationTimeList }) =>
            `${resveCourseName} ${reservationTimeList
              .map(({ resveTime }) => resveTime)
              .join(" ")}`
        )
        .join("\n")} (${this.cancelTimeList.length}팀)`;

      if (this.cancelInfo.resveSmsSendFlag) {
        if (this.cancelInfo.smsRecptnDiv === "A") {
          if (this.cancelInfo.resveContactTel) {
            sendData.receiverName = this.cancelInfo.resveName;
            sendData.name =
              this.cancelInfo.resveName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                this.cancelInfo.resveMemberDiv,
                1
              ) +
              (sendData.transname
                ? "(" + sendData.transname + ")"
                : "")
            ;
            sendData.contactTel = this.cancelInfo.resveContactTel;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }

          if (this.cancelInfo.resveMngrHp) {
            sendData.receiverName = this.cancelInfo.resveName;
            sendData.name =
              this.cancelInfo.resveName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                this.cancelInfo.resveMemberDiv,
                1
              ) +
              (sendData.transname
                ? "(" + sendData.transname + ")"
                : "")
            ;
            sendData.contactTel = this.cancelInfo.resveMngrHp;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else if (this.cancelInfo.smsRecptnDiv === "B") {
          if (this.cancelInfo.resveMngrHp) {
            sendData.receiverName = this.cancelInfo.resveName;
            sendData.name =
              this.cancelInfo.resveName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                this.cancelInfo.resveMemberDiv,
                1
              ) +
              (sendData.transname
                ? "(" + sendData.transname + ")"
                : "")
            ;
            sendData.contactTel = this.cancelInfo.resveMngrHp;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else {
          if (this.cancelInfo.resveContactTel) {
            sendData.receiverName = this.cancelInfo.resveName;
            sendData.name =
              this.cancelInfo.resveName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                this.cancelInfo.resveMemberDiv,
                1
              ) +
              (sendData.transname
                ? "(" + sendData.transname + ")"
                : "")
            ;
            sendData.contactTel = this.cancelInfo.resveContactTel;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        }
      }

      const cancelTimeData = this.$refs.cancelGrid.getBatchCurrentViewRecords();

      cancelTimeData.forEach((timeInfo) => {
        if (timeInfo.smsSendFlag) {
          if (
            timeInfo.transName !== null &&
            timeInfo.transName !== undefined &&
            timeInfo.transName.trim() !== ""
          ) {
            if (timeInfo.transContactTel) {
              sendData.receiverName = timeInfo.transName;
              sendData.name =
                this.cancelInfo.resveName +
                " " +
                commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                  "MEMBER_DIV",
                  this.cancelInfo.resveMemberDiv,
                  1
                ) +
                (sendData.transname
                  ? "(" + sendData.transname + ")"
                  : "")
              ;
              sendData.contactTel = timeInfo.transContactTel;
              sendData.transFlag = true;
              smsSendList.push(JSON.parse(JSON.stringify(sendData)));
            }
          }
        }
      });

      // SMS 전송할 데이터 없으면 RETURN.
      if (!(smsSendList.length > 0)) {
        return;
      }

      const smsSendInfo = {
        type: this.cancelInfo.isPackageCancelSms ? "P_CANCEL" : "G_CANCEL",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };

      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
        (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
        (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
        (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
          message +
          (smsSendInfo.bizName != null ? "\n" + smsSendInfo.bizName : "");
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let packageGolf = null;
      let packageRoom = null;
      if (this.cancelInfo.isPackageCancelSms) {
        packageGolf =
          Object.entries(
            _groupBy(
              _orderBy(
                this.cancelTimeList.map(time => {
                  return {
                    resveDateByYYMMDD: getFormattedDate(time.resveDate, "YY.MM.DD"),
                    dwName: commonCodesGetComName("DW_CODE", time.dwCode),
                    resveCourse: time.resveCourse,
                    resveCourseSortNo: commonCodesGetSortNo("COURSE_CODE", time.resveCourse),
                    resveTime: time.resveTime,
                  };
                }).flat(),
                ["resveDateByYYMMDD", "resveCourseSortNo", "resveTime"]
              ),
              "resveDateByYYMMDD",
            )
          );

        const arrivalDateByMin = _minBy(this.cancelInfo.roomStayRecords.map(item => item.arrivalDate));
        packageRoom =
          "입실일자 : " + getFormattedDate(arrivalDateByMin, "YY-MM-DD") + "(" + getDayOfWeekCaption(arrivalDateByMin) + ")\n" +
          "예약객실 : " +
          Object.entries(_groupBy(this.cancelInfo.roomStayRecords, "roomType"))
            .map(([key, value]) =>
              commonCodesGetComName("ROOM_TYPE", key) + "/" +
              value.length + "실/" +
              _maxBy(value.map(item => item.stayCnt)) + "박"
            ).join(", ")
        ;
      }

      let smsRequestList = [];
      smsSendList.forEach((data) => {
        let smsInfo = {
          type: smsSendInfo.type,
          kakaoFlag: smsSendInfo.kakaoSendFlag,
          smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
          kakaoDispatchKey: smsSendInfo.kakaoSendFlag
            ? smsSendInfo.kakaoDispatchKey
            : null,
          kakaoTemplateKey: smsSendInfo.kakaoSendFlag
            ? smsSendInfo.kakaoTemplateCode
            : null,
          key: data.sendKey,
          reservedFlag: false,
          reserveDateTime: null,
          receiverName: data.receiverName,
          receiverNumber: data.contactTel,
          subject: smsSendInfo.subject,
          message: message,
          replaceDataMap: {
            name: data.name,
            resveDate: data.resveDate,
            dwName: data.dwName,
            resveCourse: data.resveCourse,
            resveTime: data.resveTime,
            resveCourseTime: data.resveCourseTime,
            transResveDiv: data.transFlag === true ? "위임예약" : "예약",
            transInfo: data.transInfo || "",
            headerMemo: smsSendInfo.bizNameLocation === "UP" ? (smsSendInfo.bizName ? smsSendInfo.bizName : "") : "",
            packageGolf:
              packageGolf ?
                (packageGolf[0] ?
                  "1일차 : " +
                  packageGolf[0][0] + "(" + packageGolf[0][1][0].dwName + ") " +
                  Object.entries(_groupBy(packageGolf[0][1], "resveCourse"))
                    .map(([key, value]) =>
                      commonCodesGetComName("COURSE_CODE", key) + " " + value.map(item => item.resveTime).join(", ")
                    ).join(", ") +
                  " (" + packageGolf[0][1].length + "팀)" + (packageGolf[1] ? "\n" : "") : "") +
                (packageGolf[1] ?
                  "2일차 : " +
                  packageGolf[1][0] + "(" + packageGolf[1][1][0].dwName + ") " +
                  Object.entries(_groupBy(packageGolf[1][1], "resveCourse"))
                    .map(([key, value]) =>
                      commonCodesGetComName("COURSE_CODE", key) + " " + value.map(item => item.resveTime).join(", ")
                    ).join(", ") +
                  " (" + packageGolf[1][1].length + "팀)" : "") :
                "",
            packageRoom:
            packageRoom ?
              packageRoom :
              "",
          },
          dispatchNumber: smsSendType.dsptchNo,
          sender: "ERP",
        };

        smsRequestList.push(smsInfo);
      });

      await GolfErpAPI.sendSMS(smsRequestList);

      this.reservationCompanionAutoSendSms();
    },
    async reservationCompanionAutoSendSms() {
      let cmpnInfos = [];

      this.cancelTimeList.forEach(timeInfo => {
        timeInfo?.reservationConfirm?.reservationCmpns?.forEach(cmpnInfo => {
          cmpnInfos.push({
            name: cmpnInfo.cmpnName,
            contactTel: cmpnInfo.contactTel,
            memberDiv: cmpnInfo.memberDiv
          });
        });
      });

      cmpnInfos = cmpnInfos.filter(data =>
        !(data.name === this.cancelInfo.resveName &&
        data.contactTel === this.cancelInfo.resveContactTel) &&
        data.contactTel !== "01000000000"
      );

      let smsSendList = [];
      let sendData = {};

      let resveDateArr = [];
      let dwnameArr = [];
      _orderBy(this.cancelTimeList, ["resveDate", "resveTime"], ["asc", "asc"]).forEach(data => {
        if (!resveDateArr.includes(data.resveDate)) {
          resveDateArr.push(data.resveDate);
          dwnameArr.push(getDayOfWeekCaption(moment(data.resveDate).toDate()));
        }
      });

      sendData.sendKey = this.cancelInfo.resveNo;
      sendData.resveDate = resveDateArr.join(", ");
      sendData.dwName = dwnameArr.join(", ");
      sendData.resveCourse = commonCodesGetComName(
        "COURSE_CODE",
        this.cancelInfo.resveCourse
      );
      sendData.resveTime = this.cancelInfo.resveTime;
      sendData.transFlag = false;

      sendData.resveCourseTime = `${this.reservationCourseList
        .map(({ resveCourseCode, resveCourseName }) => ({
          resveCourseName,
          reservationTimeList: this.cancelTimeList.filter(
            (timeInfo) => timeInfo.resveCourse === resveCourseCode
          ),
        }))
        .filter(
          ({ reservationTimeList = [] }) => reservationTimeList.length !== 0
        )
        .map(
          ({ resveCourseName, reservationTimeList }) =>
            `${resveCourseName} ${reservationTimeList
              .map(({ resveTime }) => resveTime)
              .join(" ")}`
        )
        .join("\n")} (${this.cancelTimeList.length}팀)`;

      cmpnInfos.forEach(cmpnInfo => {
        sendData.receiverName = cmpnInfo.name;
        sendData.contactTel = cmpnInfo.contactTel;
        sendData.name =
          this.cancelInfo.resveName +
          " " +
          commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
            "MEMBER_DIV",
            this.cancelInfo.resveMemberDiv,
            1
          );

        smsSendList.push(JSON.parse(JSON.stringify(sendData)));
      });

      if (!(smsSendList.length > 0)) {
        return;
      }

      const smsSendInfo = {
        type: "CMPN_CANCEL",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };

      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
        (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
        (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
        (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
          message +
          (smsSendInfo.bizName != null ? "\n" + smsSendInfo.bizName : "");
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let smsRequestList = [];
      smsSendList.forEach((data) => {
        let smsInfo = {
          type: smsSendInfo.type,
          kakaoFlag: smsSendInfo.kakaoSendFlag,
          smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
          kakaoDispatchKey: smsSendInfo.kakaoSendFlag
            ? smsSendInfo.kakaoDispatchKey
            : null,
          kakaoTemplateKey: smsSendInfo.kakaoSendFlag
            ? smsSendInfo.kakaoTemplateCode
            : null,
          key: data.sendKey,
          reservedFlag: false,
          reserveDateTime: null,
          receiverName: data.receiverName,
          receiverNumber: data.contactTel,
          subject: smsSendInfo.subject,
          message: message,
          replaceDataMap: {
            name: data.name,
            resveDate: data.resveDate,
            dwName: data.dwName,
            resveCourse: data.resveCourse,
            resveTime: data.resveTime,
            resveCourseTime: data.resveCourseTime,
            transResveDiv: data.transFlag === true ? "위임예약" : "예약",
            headerMemo: smsSendInfo.bizNameLocation === "UP" ? (smsSendInfo.bizName ? smsSendInfo.bizName : "") : ""
          },
          dispatchNumber: smsSendType.dsptchNo,
          sender: "ERP",
        };

        smsRequestList.push(smsInfo);
      });

      await GolfErpAPI.sendSMS(smsRequestList);
    },
    manageLetter: function () {
      this.wordsPopupOpen();
    },
    gridCustomiseCell: function (args) {
      // 수정영역 셀 처리
      if (
        args.column.field === "penltyCode" ||
        args.column.field === "penltyApplyFlag" ||
        args.column.field === "smsSendFlag" ||
        args.column.field === "resveRtrvlMethod"
      ) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    onResveSmsSendFlagChanged() {
      if (!this.isPackageCancelSmsByDisabled) {
        this.cancelInfo.isPackageCancelSms = this.cancelInfo.resveSmsSendFlag;
      }
    },
  },
};
</script>
