<template>
  <div id="holll">
    <ejs-dialog
      ref="reservationCalendarPopup"
      :header="`예약 캘린더 조회`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1180"
      height="910"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onReservationCalendarPopupClosed"
    >
      <div class="content-wrapper">
        <div class="content-lookup">
          <div class="lookup-left"></div>
          <div class="lookup-right">
            <ul class="lookup-button">
              <li class="refresh">
                <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    :is-icon-custom="true"
                    @click.native="onRefreshButtonClicked"
                >
                  새로고침
                </erp-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="content-body">
          <ejs-schedule
            ref="calendarSchedule"
            :selectedDate="selectedDate"
            width="100%"
            cssClass="schedule-cell-dimension"
            height="100%"
            :currentView="currentView"
            :renderCell="onRenderCell"
            @navigating="onNavigating($event)"
          >
            <e-views>
              <e-view option="Month" readonly="true"></e-view>
            </e-views>
          </ejs-schedule>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import { createElement } from "@syncfusion/ej2-base";
import { Month } from "@syncfusion/ej2-vue-schedule";

import { getFormattedDate } from "@/utils/date";

import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "ReservationCalendarPopup",
  components: {
    ErpButton
  },
  provide: {
    schedule: [Month],
  },
  data: function () {
    return {
      saveMemoInfo: {
        memoContents: null,
      },
      calendarList: [],
      bsnDate: null,
      selectedDate: null,
      currentView: "Month",
      aditTeamFlag: null,
      currentDate: null,
    };
  },
  computed: {},
  methods: {
    showReservationCalendarPopup: function (bsnDate, aditTeamFlag) {
      console.log(bsnDate);
      this.bsnDate = bsnDate;
      this.selectedDate = new Date(bsnDate);
      this.aditTeamFlag = aditTeamFlag;

      this.$refs.reservationCalendarPopup.show();
    },
    onReservationCalendarPopupClosed: function () {
      this.$emit("popupClosed");
    },
    async onNavigating(args) {
      const currentDate = getFormattedDate(args.currentDate);
      const year = getFormattedDate(args.currentDate, "YYYY");
      const month = getFormattedDate(args.currentDate, "MM");

      const searchOptions = {
        year: year,
        month: month,
        div: "RESERVATION",
        isAditTeam: this.aditTeamFlag,
      };

      try {
        const reservationCalendarList = await GolfErpAPI.fetchReservationCalendar(
          searchOptions
        );

        this.calendarList = reservationCalendarList;
        this.$refs.calendarSchedule.ej2Instances.refresh();
        this.currentDate = currentDate;
      } catch (e) {
        console.error(e);
      }
    },
    onRenderCell: function (args) {
      if (args.elementType === "monthCells") {
        let cellDateStr = getFormattedDate(args.date);
        let calendarInfo = this.getCalendarInfoOfDate(cellDateStr);
        if (calendarInfo) {
          const colorValue =
            calendarInfo.dwCode === "1" || calendarInfo.dwCode === "7"
              ? commonCodesGetColorValue("DW_CODE", calendarInfo.dwCode)
              : calendarInfo.bsnCode === "WEEKEND"
              ? commonCodesGetColorValue("BSN_CODE", calendarInfo.bsnCode)
              : commonCodesGetColorValue(
                  "DW_CODE",
                  calendarInfo.dwCode.toString()
                );

          args.element.firstElementChild.style.color = colorValue;

          if (calendarInfo.allResvePercent === 100) {
            args.element.firstElementChild.style.backgroundColor = "#ffff00";
          }
        }
        const dialogVueComponent = this;

        args.element.ondblclick = function () {
          dialogVueComponent.$emit("popupClosed", args.date);
        };

        let ele = createElement("div", {
          innerHTML: this.getRenderCellInnerHtml(cellDateStr),
          // className: this.getRenderCellClassName(cellDateStr)
        });
        args.element.appendChild(ele);
      }
    },
    getRenderCellInnerHtml: function (date) {
      let calendarInfo = this.getCalendarInfoOfDate(date);
      if (calendarInfo) {
        const partDivs = commonCodesGetCommonCode("PART_DIV");
        let innerHtml = "<div>";
        partDivs.forEach((data) => {
          switch (data.comCode) {
            case "1":
              innerHtml += `<div>1부 ${calendarInfo.partOneAllTeamCountByDivision}(${calendarInfo.partOneAllTeamCount}) ${calendarInfo.partOneAllTeamPercent}%</div>`;
              break;
            case "2":
              innerHtml += `<div>2부 ${calendarInfo.partTwoAllTeamCountByDivision}(${calendarInfo.partTwoAllTeamCount}) ${calendarInfo.partTwoAllTeamPercent}%</div>`;
              break;
            case "3":
              innerHtml += `<div>3부 ${calendarInfo.partThreeAllTeamCountByDivision}(${calendarInfo.partThreeAllTeamCount}) ${calendarInfo.partThreeAllTeamPercent}%</div>`;
              break;
          }
        });

        innerHtml += `<div>총 ${calendarInfo.allTeamCountByDivision}(${calendarInfo.allTeamCount}) ${calendarInfo.allTeamPercent}%</div>`;
        innerHtml += `<div><b>${calendarInfo.dayResveStatus}</b></div>`;
        return innerHtml;
      }
    },
    getRenderCellClassName: function () {
      // TODO : 클래스명이 필요하면 여기에 추가함
      return "abcdefClassName";
    },
    getCalendarInfoOfDate: function (date) {
      if (Array.isArray(this.calendarList)) {
        return this.calendarList.find(
          (calendarInfo) => calendarInfo.bsnDate === date
        );
      } else {
        return null;
      }
    },
    onRefreshButtonClicked() {
      const args = {
        currentDate: this.currentDate,
      };

      this.onNavigating(args);
    },
  },
};
</script>
<style>
.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells {height: 120px;}
</style>
