<template>
  <div>
    <ejs-dialog
        ref="ApplyAndCancelSelectPopup"
        header="옵션 선택 팝업"
        :width="300"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :animationSettings="{ effect: 'None' }"
        :close="onApplyAndCancelSelectPopupClosed"
    >
      <div class="window">
        <div class="windowContent" v-if="requiredRemarks">
          <div class="content-wrapper">
            <article class="body-article">
                <section class="article-section section-01" >
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" style="width: 100%">
                              <!-- 필수 : required -->
                              <div class="title" :class="{required:requiredRemarks}">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                          ref="remarks"
                                          v-model="remarks"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
          </div>
        </div>
        <div class="windowFooter" :class="{borderNone: !requiredRemarks}">
          <ul class="button">
            <li class="add keyColor">
              <erp-button
                  button-div="SAVE"
                  @click.native="addCommonTimeStatus"
              >
                일괄 적용
              </erp-button>
            </li>
            <li class="delete keyColor">
              <erp-button
                  button-div="DELETE"
                  @click.native="cancelCommonTimeStatus"
              >
                일괄 해제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeApplyAndCancelSelectPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>
<style scoped>
body .window .windowContent .body-article .section-body {overflow: visible; border: none;}
body .window .windowContent .article-section.section-01 .body-data .field {width: calc(100% + 1px);}
.windowFooter .borderNone {
  border: none !important;
}
</style>
<script>
import InputTextarea from "@/components/common/text/InputTextarea";
import ErpButton from "@/components/button/ErpButton.vue";
export default {
  name: "ApplyAndCancelSelectPopup",
  components: {
    ErpButton,
    InputTextarea,
  },
  data() {
    return {
      buttonDiv: null,
      selectArray: [],
      requiredRemarks: null,
      remarks: "",
    };
  },
  methods: {
    show({buttonDiv,selectArray,requiredRemarks}) {
      this.buttonDiv = buttonDiv;
      this.selectArray = selectArray;
      this.requiredRemarks = requiredRemarks;
    },
    addCommonTimeStatus() {
      if(this.requiredRemarks && this.remarks.trim() === ""){
        this.$EventBus.$emit(
            "errToast",
            this.$t("main.validationMessage.requiredMessage", ["비고"])
        );
        return;
      }
      this.settingTimeStatusList(true);
      this.$emit("popupConfirm",this.selectArray);
      this.$refs.ApplyAndCancelSelectPopup.hide();
    },
    cancelCommonTimeStatus() {
      this.settingTimeStatusList(false);
      this.$emit("popupConfirm",this.selectArray);
      this.$refs.ApplyAndCancelSelectPopup.hide();
    },
    onApplyAndCancelSelectPopupClosed() {
      this.$emit("popupClosed");
    },
    settingTimeStatusList(timeSelect) {
       this.selectArray.forEach((rowData) => {
         rowData.settingTimeStatusRemarks = this.remarks;
         rowData.timeSelectCheck = timeSelect;
       });
    },
    closeApplyAndCancelSelectPopup() {
      this.$refs.ApplyAndCancelSelectPopup.hide();
    },
  },
};
</script>