<template>
  <div>
    <ejs-dialog
      ref="waitingReservationListPopup"
      header="대기예약 리스트"
      width="1700"
      height="800"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :animationSettings="{ effect: 'None' }"
      @close="onWaitingReservationListPopupClosed"
    >
      <div class="window reservationInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">대기일자</div>
                    <ul class="content">
                      <li class="item">
                        <input-date
                            :format="DATE_FORMAT_YYYY_MM_DD"
                            v-model="searchOptions.waitingDate"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">접수상태</div>
                    <ul class="content">
                      <li class="item" style="width: 200px;">
                        <ejs-multiselect
                            ref="rceptStatusDropdown"
                            cssClass="lookupDetail-condition-multiselect"
                            placeHolder="전체"
                            v-model="searchOptions.rceptStatus"
                            :dataSource="
                            searchOptions.rceptStatusOptions
                          "
                            :fields="{ text: 'comName', value: 'comCode' }"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div style="height: calc(100% - 36px)">
              <div class="content-body" style="height: 100%">
                <article
                    class="body-article"
                    :class="isDepositDetailViewOpened ? $t('className.reservation.reservationDetailViewOpened'): ''"
                >
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <div class="article-left">
                    <section class="article-section section-01" >
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">대기예약 목록</div>
                          <div class="header-caption">
                            [{{
                              numberWithCommas(waitingReservationListLength)
                            }}건]
                          </div>
                        </div>
                        <div class="header-right">
                          <ul class="header-button">
                            <li class="save">
                              <erp-button
                                  button-div="SAVE"
                                  :is-icon-custom="true"
                                  :is-custom-shortcut-button="true"
                                  :shortcut="{key: 'F7'}"
                                  :ignore="isPopupOpened"
                                  shortcut-key="waitingReservationListPopup.shortcuts.reservationConfirm"
                                  @click.native="waitingReservationConfirmButtonClicked"
                              >
                                예약 확정
                              </erp-button>
                            </li>
                            <li class="cancel">
                              <erp-button
                                  button-div="DELETE"
                                  :is-icon-custom="true"
                                  @click.native="waitingReservationCancelButtonClicked"
                              >
                                접수 취소
                              </erp-button>
                            </li>
                            <li class="add">
                              <erp-button
                                  button-div="SAVE"
                                  :is-icon-custom="true"
                                  :is-custom-shortcut-button="true"
                                  :shortcut="{key: 'F3'}"
                                  :ignore="isPopupOpened"
                                  @click.native="addWaitingReservation"
                              >
                                추가
                              </erp-button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="section-body">
                        <div class="body-grid">
                          <ejs-grid-wrapper
                              ref="waitingReservationGrid"
                              v-bind="bodyGridProps"
                              :dataSource="waitingReservationList"
                              @queryCellInfo="bodyGridQueryCellInfo"
                              @recordClick="selectWaitingReservation"
                              @dataBound="dataBoundEvent"
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="article-right">
                    <section class="article-section section-02" style="height: 100%;">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">대기예약 정보</div>
                        </div>
                        <div class="header-right">
                          <ul class="header-button">
                            <li class="save keyColor">
                              <erp-button
                                  button-div="SAVE"
                                  :is-shortcut-button="true"
                                  :is-key-color="true"
                                  :ignore="isPopupOpened"
                                  v-on:click.native="onSaveButtonClicked"
                              >
                                저장
                              </erp-button>
                            </li>
                            <li class="close">
                              <erp-button
                                  button-div="CLOSE"
                                  v-on:click.native="onWaitingReservationInfoCloseClick"
                              >
                                닫기
                              </erp-button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="section-body">
                        <article
                            class="body-article"
                            :class=" !reservationWaits.waitingId ? 'new': 'modify'"
                        >
                          <!-- 아코디언 : accordion / 닫힘 : close -->
                          <section class="article-section section-0101">
                            <div class="section-header">
                              <div class="header-left">
                                <div class="header-title">연락자</div>
                              </div>
                            </div>
                            <div class="section-body">
                              <div class="body-data">
                                <div class="data-outer">
                                  <div class="data-inner">
                                    <ul class="data-content">
                                      <li class="field memberNameNumber">
                                        <!-- 필수 : required -->
                                        <div class="title required">
                                          회원명/번호
                                        </div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group input">
                                              <div class="form">
                                                <input-text
                                                    id="memberName"
                                                    ref="memberName"
                                                    v-model="reservationWaits.memberName"
                                                    placeholder="회원명"
                                                    @change="onMemberNameChanged"
                                                    @keydown.enter="memberNameInputKeyEnter"
                                                    @keydown.native="preventEnterEvent"
                                                />
                                              </div>
                                            </li>
                                            <li class="item form-group text">
                                              {{ reservationWaits.memberNo }}
                                            </li>
                                            <li class="item form-group button">
                                              <ul class="button">
                                                <li class="search">
                                                  <erp-button
                                                      button-div="GET"
                                                      ref="memberPopupOpenButton"
                                                      @click.native="onMemberPopupOpen(true,memberPopupType.RESV,reservationWaits.memberName,null)"
                                                      @keypress.enter="onMemberPopupOpen(true,memberPopupType.RESV,reservationWaits.memberName,null)"
                                                  >
                                                    조회
                                                  </erp-button>

                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title required">회원구분</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="memberDiv"
                                                    v-model="reservationWaits.memberDiv"
                                                    :dataSource="reservationWaitsOption.memberDivOptions"
                                                    :allowFiltering="false"
                                                    :fields="{text: 'comName',value: 'comCode',}"
                                                    cssClass="body-data-dropdown"
                                                    @change="onReservationWaitsMemberDivDropDownListChanged"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title required">회원등급</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="memberGrade"
                                                    v-model="reservationWaits.memberGrade"
                                                    :dataSource="reservationWaitsOption.memberGradeOptions"
                                                    :allowFiltering="false"
                                                    :fields="{text: 'comName',value: 'comCode',}"
                                                    cssClass="body-data-dropdown"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title required">연락처</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <component-telephone
                                                    id="contactTel"
                                                    name="contactTel"
                                                    ref="contactTel"
                                                    v-model="reservationWaits.contactTel"
                                                    :max-length="11"
                                                    @keydown.native="preventEnterEvent"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">성별</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="popupSexCodeDropdown"
                                                    v-model="reservationWaits.sexCode"
                                                    :dataSource="reservationWaitsOption.sexCodeOptions"
                                                    :allowFiltering="false"
                                                    :fields="{text: 'comName',value: 'comCode',}"
                                                    cssClass="body-data-dropdown"
                                                ></ejs-dropdownlist>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">지역</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="popupAreaCodeDropdown"
                                                    v-model="reservationWaits.areaCode"
                                                    :dataSource="reservationWaitsOption.areaCodeOptions"
                                                    :allowFiltering="false"
                                                    :fields="{text: 'comName',value: 'comCode'}"
                                                    cssClass="body-data-dropdown"
                                                ></ejs-dropdownlist>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                          </section>
                          <!-- 아코디언 : accordion / 닫힘 : close -->
                          <section class="article-section section-0102">
                            <div class="section-header">
                              <div class="header-left">
                                <div class="header-title">대기 신청</div>
                              </div>
                            </div>
                            <div class="section-body">
                              <div class="body-data">
                                <div class="data-outer">
                                  <div class="data-inner">
                                    <ul class="data-content">
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title required">대기일자</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-date
                                                    id="waitingDate"
                                                    ref="waitingDate"
                                                    v-model="reservationWaits.waitingDate"
                                                    type="body-data"
                                                    format="YYYY-MM-DD"
                                                    @keydown.native="preventEnterEvent"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title required">
                                          희망시간 1
                                        </div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="hopeTime1"
                                                    v-model="reservationWaits.hopeTime1"
                                                    :dataSource="reservationWaitsOption.hopeTimeOptions"
                                                    :allowFiltering="false"
                                                    :fields="{text: 'comName',value: 'comCode',}"
                                                    cssClass="body-data-dropdown"
                                                ></ejs-dropdownlist>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">희망시간 2</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="hopeTime2"
                                                    v-model="reservationWaits.hopeTime2"
                                                    :dataSource="reservationWaitsOption.hopeTimeOptions"
                                                    :allowFiltering="false"
                                                    :fields="{text: 'comName',value: 'comCode',}"
                                                    cssClass="body-data-dropdown"
                                                ></ejs-dropdownlist>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">희망코스</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="popupHopeCourseDropdown"
                                                    v-model="reservationWaits.hopeCourse"
                                                    :dataSource="reservationWaitsOption.hopeCourseOptions"
                                                    :allowFiltering="false"
                                                    :fields="{text: 'comName',value: 'comCode'}"
                                                    cssClass="body-data-dropdown"
                                                ></ejs-dropdownlist>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">희망홀 구분</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="popupHopeHoleDivDropdown"
                                                    v-model="reservationWaits.hopeHoleDiv"
                                                    :dataSource="reservationWaitsOption.hopeHoleDivOptions"
                                                    :allowFiltering="false"
                                                    :fields="{text: 'comName',value: 'comCode',}"
                                                    cssClass="body-data-dropdown"
                                                ></ejs-dropdownlist>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">희망팀수</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-number
                                                    v-model="reservationWaits.hopeTeamCnt"
                                                    v-on:keydown.native="preventEnterEvent"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">셀프라운드</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group check">
                                              <!-- 포커스 : focus -->
                                              <ul class="check">
                                                <li>
                                                  <label>
                                                    <input
                                                        type="radio"
                                                        id="selfFlagTrue"
                                                        v-model="reservationWaits.selfFlag"
                                                        value="true"
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      노캐디
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                        type="radio"
                                                        id="selfFlagFalse"
                                                        v-model="reservationWaits.selfFlag"
                                                        value="false"
                                                    />
                                                    <i></i>
                                                    <div class="label">캐디</div>
                                                  </label>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">조인 신청</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group check">
                                              <!-- 포커스 : focus -->
                                              <ul class="check">
                                                <li>
                                                  <label>
                                                    <input
                                                        type="radio"
                                                        id="joinReqstFlagTrue"
                                                        v-model="reservationWaits.joinReqstFlag"
                                                        value="true"
                                                    />
                                                    <i></i>
                                                    <div class="label">예</div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                        type="radio"
                                                        id="joinReqstFlagFalse"
                                                        v-model="reservationWaits.joinReqstFlag"
                                                        value="false"
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      아니오
                                                    </div>
                                                  </label>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field requestContent">
                                        <!-- 필수 : required -->
                                        <div class="title">요청 내용</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-textarea
                                                    v-model="reservationWaits.requstContents"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field requestContent">
                                        <!-- 필수 : required -->
                                        <div class="title">메모</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-textarea v-model="reservationWaits.memoContents"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                          </section>
                          <!-- 아코디언 : accordion / 닫힘 : close -->
                          <section class="article-section section-0103">
                            <div class="section-header">
                              <div class="header-left">
                                <div class="header-title">접수상태</div>
                              </div>
                            </div>
                            <div class="section-body">
                              <div class="body-data">
                                <div class="data-outer">
                                  <div class="data-inner">
                                    <ul class="data-content">
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">접수상태</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              {{
                                                commonCodesGetComName(
                                                    "RCEPT_STATUS",
                                                    reservationWaits.rceptStatus
                                                )
                                              }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">확정시간</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              {{ reservationWaits.resveTime }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">확정코스</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              {{
                                                commonCodesGetComName(
                                                    "COURSE_CODE",
                                                    reservationWaits.resveCourse
                                                )
                                              }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                          </section>
                          <!-- 아코디언 : accordion / 닫힘 : close -->
                          <section class="article-section section-0104">
                            <div class="section-header">
                              <div class="header-left">
                                <div class="header-title">접수자</div>
                              </div>
                            </div>
                            <div class="section-body">
                              <div class="body-data">
                                <div class="data-outer">
                                  <div class="data-inner">
                                    <ul class="data-content">
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">접수자</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              {{ reservationWaits.insertName }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <!-- 필수 : required -->
                                        <div class="title">접수일시</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              {{ reservationWaits.insertDt }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                          </section>
                        </article>
                      </div>
                    </section>
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div class="windowFooter">
                  <ul class="button">
                    <li class="close">
                      <erp-button button-div="CLOSE" @click.native="popupClosed">
                        닫기
                      </erp-button>
                    </li>
                  </ul>
                </div>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
        v-if="isMemberSelectPopupOpen"
        ref="memberSelectPopup"
        :position="{ x: 'center', y: 'center' }"
        :isModal="true"
        @popupClosed="memberSelectPopupClosed"
        @popupConfirmed="memberSelectPopupConfirmed"
    />
    <reservation-list-popup
        v-if="isReservationListPopupOpen"
        ref="reservationListPopup"
        :position="{ x: 'center', y: 'center' }"
        :isModal="true"
        @popupClosed="reservationListPopupClosed"
        @popupConfirmed="reservationListPopupConfirmed"
    />
  </div>
</template>

<script>
import { DATE_FORMAT_YYYY_MM_DD } from '@/utils/date';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  Aggregate,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
  Edit,
  Group,
  Filter,
} from "@syncfusion/ej2-vue-grids";
import InputDate from "@/components/common/datetime/InputDate";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import InputNumber from "@/components/common/InputNumber";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton.vue";

import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetJsonData
} from "@/utils/commonCodes";
import {getDayColorValue, getFormattedDate} from "@/utils/date";
import {numberWithCommas} from "@/utils/number";
import {gridUtilGetMemberNoColumnAccess, gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import {
  getReservationWaitCount,
  getWaitingReservation,
  saveReservationWaitCancel,
  saveWaitingReservation
} from "@/api/waitingReservation";
import {formPreventEnterEvent,validateFormRefs} from "@/utils/formUtil";
import ReservationListPopup from "@/views/golf-reservation/popup/ReservationListPopup";

export default {
  name: "WaitingReservationListPopup",
  components: {
    ReservationListPopup,
    ComponentTelephone,
    InputTextarea,
    InputText,
    InputNumber,
    memberSelectPopup,
    InputDate,
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {},
  data() {
    return {
      selectRowKey: null,
      DATE_FORMAT_YYYY_MM_DD,
      validateRefList: {
        memberName: {
          required: true,
        },
        memberDiv: {
          required: true,
        },
        memberGrade: {
          required: true,
        },
        waitingDate: {
          required: true,
        },
        hopeTime1: {
          required: true,
        },
        contactTel: {
          required: true,
        },
      },
      memberPopupType: {
        RESV: "RESV", // 예약자
        TRANS: "TRANS", // 위임자
        COMP: "COMP", // 동반자
      },
      reservationWaits: {
        waitingId: null,
        memberName: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        contactTel: "",
        sexCode: null,
        areaCode: null,
        waitingDate: null,
        hopeTime1: null,
        hopeTime2: null,
        hopeCourse: null,
        hopeHoleDiv: "18",
        hopeTeamCnt: 1,
        selfFlag: false,
        joinReqstFlag: false,
        requstContents: null,
        memoContents: null,
        rceptStatus: "RCEPT",
        rceptStatusName: "접수등록",
        resveTime: null,
        resveCourse: null,
        resveCourseName: null,
        insertId: null,
        insertDt: null,
      },
      refreshFlag: false,
      reservationWaitsOption:{
        hopeTimeOptions: [],
        resveCourseOptions: [],
        memberDivOptions: [],
        memberGradeOptions: [],
        sexCodeOptions: [],
        areaCodeOptions: [],
        hopeCourseOptions: [],
        hopeHoleDivOptions: [],
        rceptStatusOptions: [],
      },
      isMemberSelectPopupOpen: false,
      isReservationListPopupOpen: false,
      isDepositDetailViewOpened: false,
      popupData: {},
      waitingReservationList: [],
      waitingReservationListLength: 0,
      searchOptions: {
        rceptStatus: ["RCEPT"],
        waitingDate: null,
        rceptStatusOptions: [],
      }
    };
  },
  created() {
    this.searchOptions.rceptStatusOptions = commonCodesGetCommonCode("RCEPT_STATUS", true);
  },
  destroyed() {},
  computed: {
    isPopupOpened() {
      return (this.isReservationListPopupOpen || this.isMemberSelectPopupOpen);
    },
    bodyGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        allowSorting: false,
        allowFiltering: false,
        allowGrouping: false,
        allowExcelExport: false,
        noColumnWidth: 34,
        columns: [
          {
            field: "waitingId",
            allowEditing: false,
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "waitingDateGroup",
            visible: false,
          },
          {
            field: "waitingDate",
            headerText: "대기일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "dwName",
            headerText: "요일",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            field: "rank",
            headerText: "대기순번",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "memberName",
            headerText: "회원명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "left",
          },
          {
            field: "memberNo",
            headerText: "회원번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            valueAccessor: (field, data) =>
              gridUtilGetMemberNoColumnAccess(field, data),
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_GRADE",
          },
          {
            field: "contactTel",
            headerText: "연락처",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "left",
            valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
          },
          {
            headerText: "예약횟수",
            width: 140,
            columns: [
              {
                field: "weekdayCount",
                headerText: "주중",
                width: 70,
                type: "number",
                format: "N",
                textAlign: "center",
                allowEditing: false
              },
              {
                field: "weekendCount",
                headerText: "주말",
                width: 70,
                type: "number",
                format: "N",
                textAlign: "center",
                allowEditing: false
              }
            ]
          },
          {
            field: "resveInfo",
            headerText: "예약상세",
            width: 210,
            type: "string",
            allowEditing: false
          },
          {
            headerText: "위약정보",
            columns: [
              {
                field: "penltyCount",
                headerText: "횟수",
                width: 90,
                type: "number",
                format: "N",
                textAlign: "center",
                allowEditing: false
              },
              {
                field: "penltyDayCount",
                headerText: "제재일수",
                width: 90,
                type: "number",
                format: "N",
                textAlign: "center",
                allowEditing: false
              }
            ]
          },
          {
            field: "rceptStatus",
            headerText: "접수상태",
            allowEditing: false,
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RCEPT_STATUS",
          },
          {
            field: "hopeTime1",
            headerText: "희망시간1",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            editType: "dropdownedit",
            dataSource: this.reservationWaitsOption.hopeTimeOptions,
          },
          {
            field: "hopeTime2",
            headerText: "희망시간2",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            editType: "dropdownedit",
            dataSource: this.reservationWaitsOption.hopeTimeOptions,
          },
          {
            field: "requstContents",
            headerText: "요청내용",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 180,
            textAlign: "left",
          },
          {
            field: "memoContents",
            headerText: "메모",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 180,
            textAlign: "left",
          },
          {
            field: "insertName",
            headerText: "등록자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 140,
            textAlign: "left",
          },
          {
            field: "resveDate",
            headerText: "예약일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "resveDayOfTheWeekKorean",
            headerText: "요일",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            field: "resveTime",
            headerText: "시간",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            field: "resveCourse",
            headerText: "코스",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "COURSE_CODE",
          }
        ],
      };
    },
  },
  mounted() {
    this.reservationWaitsOption.hopeTimeOptions = commonCodesGetCommonCode(
        "HOPE_TIME_ZONE"
    );
    this.reservationWaitsOption.resveCourseOptions = commonCodesGetCommonCode(
        "COURSE_CODE"
    );
    this.reservationWaitsOption.memberDivOptions = commonCodesGetCommonCode(
        "MEMBER_DIV"
    );
    this.reservationWaitsOption.memberGradeOptions = commonCodesGetCommonCode(
        "MEMBER_GRADE"
    );
    this.reservationWaitsOption.sexCodeOptions = commonCodesGetCommonCode(
        "SEX_CODE"
    );
    this.reservationWaitsOption.areaCodeOptions = commonCodesGetCommonCode(
        "AREA_CODE"
    );
    this.reservationWaitsOption.hopeCourseOptions = commonCodesGetCommonCode(
        "COURSE_CODE"
    );
    this.reservationWaitsOption.hopeHoleDivOptions = commonCodesGetCommonCode(
        "HOLE_DIV"
    );
    this.reservationWaitsOption.rceptStatusOptions = commonCodesGetCommonCode(
        "RCEPT_STATUS"
    );
  },
  methods: {
    validateFormRefs,
    commonCodesGetComName,
    getFormattedDate,
    numberWithCommas,
    preventEnterEvent: formPreventEnterEvent,
    showPopup(popupData) {
      this.popupData = popupData;
      this.searchOptions.waitingDate = popupData.resveDate;
      this.onViewButtonClicked();
    },
    selectWaitingReservation({rowData:{ waitingId }}) {
      if(!this.isDepositDetailViewOpened) {this.isDepositDetailViewOpened = true;}
      this.getWaitingReservationInfo(waitingId);
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
    dataBoundEvent() {
        const row = this.waitingReservationList.findIndex((waitingList) => waitingList.waitingId === this.selectRowKey);
        row !== -1 ?  this.$refs.waitingReservationGrid.selectRow(row) : this.$refs.waitingReservationGrid.selectRow(0);
        this.selectRowKey = null;
    },
    async getWaitingReservationInfo(waitingId) {
      const bsnCode = null;
      const memberName = null;
      const memberNameNumber = null;
      const memberNumber = null;
      const rceptStatus = null;
      const waitingDateFrom = null;
      const waitingDateTo = null;

      const { value:{reservationWaits}} = await getWaitingReservation(
          waitingId,
          bsnCode,
          memberName,
          memberNameNumber,
          memberNumber,
          rceptStatus,
          waitingDateFrom,
          waitingDateTo
      );
      this.reservationWaits = reservationWaits[0];
    },

    onMemberPopupOpen(
        isOpenedWithButton,
        popupType,
        memberNameNo,
        timeInfoIdx,
        selectedIdx,
        memberDivChanged = false,
        memberGradeChanged = false
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberDivChanged = memberDivChanged;
      memberData.memberGradeChanged = memberGradeChanged;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    addWaitingReservation() {
      this.isDepositDetailViewOpened = true;
      this.reservationWaits = {
        waitingId: null,
        memberName: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        contactTel: "",
        sexCode: null,
        areaCode: null,
        waitingDate: this.searchOptions.waitingDate,
        hopeTime1: null,
        hopeTime2: null,
        hopeCourse: null,
        hopeHoleDiv: "18",
        hopeTeamCnt: 1,
        selfFlag: false,
        joinReqstFlag: false,
        requstContents: null,
        memoContents: null,
        rceptStatus: "RCEPT",
        rceptStatusName: "접수등록",
        resveTime: null,
        resveCourse: null,
        resveCourseName: null,
        insertId: null,
        insertDt: null,
      };
      setTimeout(() => {
        this.$refs.memberName.focus();
      },100);
    },
    onWaitingReservationListPopupClosed() {
      if(this.refreshFlag) {
        this.$emit('dataRefresh');
      }
      this.$emit("popupClosed");
    },
    popupClosed() {
      this.$refs.waitingReservationListPopup.hide();
    },
    onViewButtonClicked() {
      this.onWaitingReservationInfoCloseClick();
      this.getWaitingReservation();
    },
    getWaitingReservation() {
      const rceptStatusList =
        this.searchOptions.rceptStatus?.length < 1 ||
        this.searchOptions.rceptStatus?.length ===
        commonCodesGetCommonCode("RCEPT_STATUS", true)?.length
          ? null
          : this.searchOptions.rceptStatus.toString();
      getWaitingReservation(
        null,
        null,
        null,
        null,
        null,
        rceptStatusList,
        this.searchOptions.waitingDate,
        this.searchOptions.waitingDate
      )
        .then((response) => {
          this.waitingReservationList = response.value.reservationWaits;
          this.waitingReservationListLength = response.value.reservationWaits.length;
        })
        .catch((error) => {
          console.log("getWaitingReservation.err.===>", error);
        });
    },
    onMemberNameChanged(args) {
      if (args.event && !!args.value) {
        // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
        // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

        // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
        // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
        // #2. 회원/비회원 위임자의 경우
        // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명을 예약자명과 동일하게 설정
        // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
        //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
        //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
        let searchConditions = {};
        searchConditions.memberNameNo = args.value;

        // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
        this.onMemberPopupOpen(
            false,
            this.memberPopupType.RESV,
            args.value,
            null
        );
      }
    },
    memberNameInputKeyEnter() {
      this.$refs.memberPopupOpenButton.focus();
    },
    bodyGridQueryCellInfo(args) {
      const {
        column: { field },
        cell,
        data,
      } = args;
      if (data.rceptStatus === "RCEPT_CANCEL") {
        cell.style.textDecoration = "line-through";
        cell.style.textDecorationColor = "red";
      }
      if (field === "rceptStatus") {
        cell.style.color = commonCodesGetColorValue(
          "RCEPT_STATUS",
          args.data.rceptStatus
        );
      }
      // if (field === "check") {
      //   cell.classList.add(this.$t("className.grid.modifyArea"));
      // }
      if(field === "memberName" && data.penltyCount) {
        cell.style.background = "#ff0000";
      }
      if (
        (field === "weekdayCount" && data.weekdayCount < 1) ||
        (field === "weekendCount" && data.weekendCount < 1) ||
        (field === "penltyCount" && data.penltyCount < 1) ||
        (field === "penltyDayCount" && data.penltyDayCount < 1)
      ) {
        cell.innerText = "-";
      }
      if (
        field === "memberName" ||
        field === "memberNo" ||
        (field === "resveInfo" && data.resveInfo) ||
        (field === "penltyCount" && data.penltyCount) ||
        (field === "penltyDayCount" && data.penltyDayCount)
      ) {
        // cell.classList.add(this.$t("className.grid.clickArea"));
      } else if (field === "dwName") {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      }
    },
    onSaveButtonClicked() {
      if (
          this.reservationWaits.rceptStatus === "RESVE" ||
          this.reservationWaits.rceptStatus === "RESVE_CANCEL" ||
          this.reservationWaits.rceptStatus === "RCEPT_CANCEL"
      ) {
        this.errorToast(
            "접수상태가 접수취소/예약확정/예약취소는 데이터를 변경하실 수 없습니다"
        );
        return;
      }
      const validateRtn = this.validate();

      if (validateRtn !== true) {
        return;
      }

      this.getReservationWaitCount(this.reservationWaits);
    },
    onWaitingReservationInfoCloseClick() {
      this.isDepositDetailViewOpened = false;
    },
    getReservationWaitCount(data) {
      const waitingDate = data.waitingDate;
      const memberName = data.memberName;
      getReservationWaitCount(waitingDate, memberName)
          .then(async (response) => {
            if (
                this.reservationWaits.waitingId === null ||
                this.reservationWaits.waitingId === ""
            ) {
              if (response.value > 0) {
                if (
                    !(await this.confirm(
                        waitingDate +
                        " " +
                        memberName +
                        "님(접수등록)은 이미 대기예약 등록되어있습니다.\n\n추가 등록하시겠습니까?"
                    ))
                ) {
                  return;
                }
              }
            }
            this.saveWaitingReservation(data);
          })
          .catch((error) => {
            console.log("getReservationWaitCount.err.===>", error);
          });
    },
    saveWaitingReservation(data) {
      saveWaitingReservation(data)
          .then((response) => {
            if (response.value != null) {
              this.reservationWaits.waitingId = response.value;
            }
            this.selectRowKey = response.value;
            this.infoToast(this.$t("main.popupMessage.saved"));
            this.refreshFlag = true;
            this.onViewButtonClicked();
          })
          .catch((error) => {
            console.log("saveWaitingReservation.err.===>", error);
          });
    },
    waitingReservationConfirmButtonClicked() {
      const data = this.$refs.waitingReservationGrid.getSelectedRecords();
      if (data.length === 0) {
        this.errorToast("데이터를 선택하여 주시기 바랍니다");
        return;
      }
      this.isReservationListPopupOpen = true;

      this.$nextTick(() => {
        this.$refs.reservationListPopup.show(data[0]);
      });
    },
    async waitingReservationCancelButtonClicked() {
      const data = this.$refs.waitingReservationGrid.getSelectedRecords();

      if (data.length === 0) {
        this.errorToast("데이터를 선택하여 주시기 바랍니다");
        return;
      }

      let notRceptCnt = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].rceptStatus !== "RCEPT") {
          notRceptCnt++;
        }
      }

      if (notRceptCnt > 0) {
        this.errorToast(
            '접수상태가 "접수등록"인 데이터만 접수취소가 가능합니다'
        );
        return;
      }

      if (
          !(await this.confirm('선택한 대기예약정보를 "접수취소" 하시겠습니까?'))
      ) {
        return;
      }

      saveReservationWaitCancel(data)
          .then(() => {
            this.refreshFlag = true;
            this.onViewButtonClicked();
          })
          .catch((error) => {
            console.log("saveReservationWaitCancel.err.===>", error);
          });
    },
    memberSelectPopupClosed: function (data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.popupData.memberDivChanged) {
          let commonCodeJsonDataStr = this.reservationWaitsOption.memberDivOptions.find(
              (commonCode) =>
                  commonCode.comCode === this.reservationWaits.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                  (jsonData) => jsonData.IDX === 4
              );
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                // 회원구분 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
                // 회원구분 변경한 값은 그대로 유지.
                // MEMBERSHIP_ID, MEMBER_NO = 기존값 그대로 유지.
                // MEMBER_DIV = 변경한 값
                // MEMBER_GRADE = 회원구분 변경에 따른 값 셋팅.
                this.reservationWaits.memberGrade =
                    memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else if (data.popupData.memberGradeChanged) {
          // 회원등급 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
          // 회원등급 변경한 값은 그대로 유지.
          // MEMBERSHIP_ID, MEMBER_NO, MEMBER_DIV = 기존값 그대로 유지.
          // MEMBER_GRADE = 변경한 값
        } else {
          if (!data.popupData.isOpenedWithButton) {
            // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
            this.setResvNonMemberInfos();
          }
        }
      }
    },
    memberSelectPopupConfirmed: function (data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.selectedRowData) {
          if (data.selectedRowData.memberDiv == "NOM") {
            this.setResvNonMemberInfos(data.selectedRowData);
          } else {
            this.setResvMemberInfos(data.selectedRowData);
          }
        }
      }
    },
    reservationListPopupClosed() {
      this.isReservationListPopupOpen = false;
    },
    reservationListPopupConfirmed() {
      this.reservationListPopupClosed();
      this.onViewButtonClicked();
    },
    setResvMemberInfos: function (memberInfo) {
      if (this.reservationWaits) {
        this.reservationWaits.memberName = memberInfo.memberName;
        this.reservationWaits.membershipId = memberInfo.membershipId;
        this.reservationWaits.memberNo = memberInfo.memberNo;
        this.reservationWaits.memberDiv = memberInfo.memberDiv;
        this.reservationWaits.memberGrade = memberInfo.memberGrade;
        this.reservationWaits.sexCode = memberInfo.sexCode;
        this.reservationWaits.contactTel = memberInfo.hpNo;
        this.reservationWaits.areaCode = memberInfo.areaCode;
      }
    },
    setResvNonMemberInfos: function (nomMember) {
      if (this.reservationWaits) {
        this.reservationWaits.memberName = nomMember.memberName || "";
        this.reservationWaits.membershipId = "";
        this.reservationWaits.memberNo = "";
        this.reservationWaits.memberDiv = "NOM";
        this.reservationWaits.memberGrade = commonCodesGetJsonData(
            "MEMBER_DIV",
            "NOM"
        ).find((codeJson) => codeJson.IDX === 4).ATTRB;
        this.reservationWaits.sexCode = "M";
        this.reservationWaits.contactTel = "";
        this.reservationWaits.areaCode = null;
      }
    },
    onReservationWaitsMemberDivDropDownListChanged(args) {
      if (
          args.e instanceof MouseEvent ||
          args.e instanceof KeyboardEvent ||
          args.e instanceof TouchEvent
      ) {
        if (args.value === "NOM") {
          let commonCodeJsonDataStr = this.reservationWaitsOption.memberDivOptions.find(
              (commonCode) =>
                  commonCode.comCode === this.reservationWaits.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                  (jsonData) => jsonData.IDX === 4
              );
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                this.reservationWaits.membershipId = "";
                this.reservationWaits.memberNo = "";
                this.reservationWaits.memberGrade =
                    memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else {
          // 회원구분이 비회원이 아니면 동명이인 회원 검색 팝업 호출.
          this.onMemberPopupOpen(
              false,
              this.memberPopupType.RESV,
              this.reservationWaits.memberName,
              null,
              null,
              true
          );
        }
      }
    },
  }
};
</script>
<style scoped>
body .body-article .article-left{transition:all 0.3s ease-out}
body .body-article .article-right{transition:all 0.3s ease-out}
body .body-article .article-left{width:100%;}
body .body-article .article-right{width:50%;margin: 0 -50% 0 0;}
body .body-article.dev-reservation-detail-view-opened .article-left{width:calc(100% - 50%)}
body .body-article.dev-reservation-detail-view-opened .article-right{margin:0}
body .body-article .new {background: transparent url('../../../assets/images/common/window-add.png') no-repeat left top}
body .body-article .modify {background: transparent url('../../../assets/images/common/window-modify.png') no-repeat left top}
body .body-article .article-left .section-body {border: none ;}
</style>
