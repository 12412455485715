<template>
  <div>
    <ejs-dialog
      ref="reservationCopyPopup"
      :header="'예약 복사'"
      :allowDragging="true"
      :showCloseIcon="true"
      :width="popupDialogWidth"
      height="800"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onReservationCopyPopupClosed"
    >
      <div class="window reservationMove">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field promotionName">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          v-model="copyReservationDateRange"
                          type="lookup-condition"
                          @change="search"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    @click.native="search"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-box">
                      <ul>
                        <li>
                          예약일자 :
                          {{ getDayOfWeekAndCaption(popupData.resveDate) }}
                        </li>
                        <li>예약시간 : {{ popupData.resveTime }}</li>
                        <li>
                          예약코스 :
                          {{
                            commonCodesGetComName(
                              "COURSE_CODE",
                              popupData.resveCourse
                            )
                          }}
                        </li>
                      </ul>
                      <ul>
                        <li>예약자명 : {{ popupData.resveName }}</li>
                        <li>단체명 : {{ popupData.grpName }}</li>
                      </ul>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div
                    class="section-body"
                    :class="reservationCourseSectionBodyClassName"
                  >
                    <ejs-grid-wrapper
                      ref="copyReservationGrid"
                      v-for="(
                        timeTableCourse, timeTableCourseIdx
                      ) in reservationPossibleTimeCourseLists"
                      :key="timeTableCourse.resveCourseCode"
                      :provides="copyReservationGridOptions().provides"
                      :columns="
                        copyReservationGridOptions().columns(
                          timeTableCourse.resveCourseName
                        )
                      "
                      :selectionSettings="selectionSettings"
                      :dataSource="timeTableCourse.reservationPossibleTimeLists"
                      :allowResizing="true"
                      :isSelectedRowRetain="false"
                      @click.native="courseDivClicked(timeTableCourseIdx)"
                      @queryCellInfo="copyReservationQueryCellInfo"
                      @headerCellInfo="copyReservationHeaderCellInfo"
                      @rowSelected="gridSelected($event, timeTableCourseIdx)"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li style="margin-top: 6px; margin-right: 9px;">
              <label>
                <input
                  :class="[
                    'f-check',
                    isSmsSend ? 'active' : undefined,
                  ]"
                  style="float: left;"
                  type="checkbox"
                  v-model="isSmsSend"
                >
                <div style="float: left; user-select: none;">
                  SMS 발송
                </div>
              </label>
            </li>
            <li class="move keyColor">
              <ejs-button
                :disabled="
                  commonMixinIsButtonDisableByAuth('resveReceiptResveReg')
                "
                @click.native="saveReservationCopyPopup()"
              >
                예약 복사
              </ejs-button>
            </li>
            <li class="close">
              <ejs-button @click.native="closeReservationCopyPopup()">
                닫기
              </ejs-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
/* 임시 퍼블리싱 */
body .f-check {width: 13px;height: 13px;-webkit-appearance: none;-moz-appearance: none;border-color: #398a6a;background-color: #398a6a;cursor: pointer;}
body .f-check:not(.active) {border: 1px solid #e0e0e0;background-color: #fff;}
body .f-check.active:before {display: block;width: 7px;height: 7px;margin: 3px;background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAA/CAYAAAAse9ITAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA25pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo1YmQyNTVjNC1kODhlLWJiNDItOTI4MC03NGM1YjllNGM5NmMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzZDQTUyRTFDNTg4MTFFOThBMjU4RTkxRDYyODBDMEUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzZDQTUyRTBDNTg4MTFFOThBMjU4RTkxRDYyODBDMEUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Qjg5NEYzMkJDNTg0MTFFOThENTc5MUY1MDFDNzIwMUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Qjg5NEYzMkNDNTg0MTFFOThENTc5MUY1MDFDNzIwMUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7ftzCXAAAAaUlEQVR42mL8//8/Ay7AxIAb/Mcl+R+m8z82CSBgZEITgEvAdDJik0B2ECO6BLprGdFdxXLQwmK4eEVC//dw8QqX3ffh4hWfC/rDxSvMf6KHi1c0z/4cLl7R//d/uHjlrvbuoeMVgAADAJBhTr8wER99AAAAAElFTkSuQmCC) no-repeat center 0;content: '';}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import Vue from "vue";
import { Edit, Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";

import { getDayOfWeekAndCaption, } from "@/utils/date";
import {
  commonCodesGetColorValue,
  commonCodesGetComName,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import { getReservationPossibleTimeList } from "@/api/reservation";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { reservationCourseGridSectionBodyClassName } from "@/utils/gridUtil";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "ReservationCopyPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: { EjsGridWrapper, InputDateRange, ErpButton, },
  data: function () {
    return {
      isReload: false,
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      isSmsSend: true,
      popupDialogWidth: null,
      popupData: {},
      timeSelected: {}, // 선택한 time object
      copyOption: {
        resveDateFrom: null,
        resveDateTo: null,
      },
      reservationPossibleTimeCourseLists: [],
      courseActiveIndex: -1,
      numberTemplate: function () {
        return {
          template: Vue.component("editOption", {
            template: "<span>{{parseInt(data.index) + 1}}</span>",
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
      courseDivColumns: [
        {
          field: "timeId",
          headerText: "타임ID",
          allowEditing: false,
          isPrimaryKey: true,
          visible: false,
          type: "number",
        },
        {
          field: "resveDate",
          allowEditing: false,
          headerText: "일자",
          type: "string",
          minWidth: "16",
          width: "90",
          textAlign: "center",
        },
        {
          field: "dwName",
          allowEditing: false,
          headerText: "요일",
          type: "string",
          minWidth: "16",
          textAlign: "center",
        },
        {
          field: "partDiv",
          allowEditing: false,
          headerText: "부",
          type: "string",
          minWidth: "16",
          width: "60",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "PART_DIV",
        },
        {
          field: "resveTime",
          allowEditing: false,
          headerText: "시간",
          type: "string",
          minWidth: "16",
          width: "90",
          textAlign: "center",
        },
      ],
    };
  },
  computed: {
    copyReservationDateRange: {
      get: function () {
        return {
          from: this.copyOption.resveDateFrom,
          to: this.copyOption.resveDateTo,
        };
      },
      set: function (copyReservationDateRange) {
        this.copyOption.resveDateFrom = copyReservationDateRange.from;
        this.copyOption.resveDateTo = copyReservationDateRange.to;
      },
    },
    reservationCourseSectionBodyClassName() {
      return reservationCourseGridSectionBodyClassName(
        this.reservationPossibleTimeCourseLists,
        this.$t("className.reservation.reservationCourseNumPrefix")
      );
    },
  },
  created: function () {
    const courseCodes = commonCodesGetCommonCode("PART_DIV", true);

    this.popupDialogWidth = Math.round(393.3 * courseCodes.length);
  },
  methods: {
    getDayOfWeekAndCaption: getDayOfWeekAndCaption,
    commonCodesGetComName: commonCodesGetComName,
    copyReservationGridOptions() {
      const courseDivColumns = JSON.parse(
        JSON.stringify(this.courseDivColumns)
      );
      return {
        provides: [Edit, Resize, ForeignKey],
        columns: (reserveCourseName) => [
          {
            headerText: `${reserveCourseName}코스`,
            allowEditing: true,
            columns: courseDivColumns,
            textAlign: "center",
          },
        ],
      };
    },
    search: function () {
      // TODO : 예약 가능 타임 리스트 조회 API 호출
      console.log(JSON.stringify(this.copyOption, null, 2));
      this.timeSelected = {}; // 선택한 time 초기화
      this.getTimeInfos();
    },
    getTimeInfos: function () {
      getReservationPossibleTimeList(
        this.copyOption.resveDateFrom,
        this.copyOption.resveDateTo
      )
        .then((response) => {
          this.reservationPossibleTimeCourseLists =
            response.value.reservationPossibleTimeCourseLists;
        })
        .catch((error) => {
          console.log("getReservationPossibleTimeList.err.===>", error);
        });
    },
    copyReservationHeaderCellInfo(args) {
      if (args.cell.column.headerText === "NO") {
        args.node.classList.add(this.$t("className.grid.noSortFilter"));
      }
    },
    onReservationCopyPopupClosed: function () {
      this.$emit("popupClosed", { isReload: this.isReload, time: this.timeSelected });
    },
    popupOpen: async function (popupData, resveDateFrom, resveDateTo) {
      console.log(JSON.stringify(popupData, null, 2));
      this.popupData = popupData;

      // 날짜 설정
      this.copyOption.resveDateFrom = resveDateFrom;
      this.copyOption.resveDateTo = resveDateTo;

      // 조회 후 popup open
      this.getTimeInfos();
      this.$refs.reservationCopyPopup.show();
    },
    closeReservationCopyPopup: function () {
      this.$refs.reservationCopyPopup.hide();
    },
    saveReservationCopyPopup: async function () {
      if (
        this.timeSelected.timeId === null ||
        this.timeSelected.timeId === undefined
      ) {
        this.errorToast("복사하실 시간을 선택해 주십시오");
      } else {
        // 예약 이동 API 호출
        if (!(await this.confirm(
          "예약일자 : " + this.timeSelected.resveDate +
          "<br>예약코스 : " + commonCodesGetComName("COURSE_CODE", this.timeSelected.resveCourse) +
          "<br>예약시간 : " + this.timeSelected.resveTime +
          "<br><br>위 타임으로 복사하시겠습니까?"
        ))) {
          return;
        }

        await GolfErpAPI.reservationCopy({
          copyTimeId: this.popupData.timeId,
          newTimeId: this.timeSelected.timeId,
          isSmsSend: this.isSmsSend,
        });

        this.isReload = true;

        this.$refs.reservationCopyPopup.hide();
      }
    },
    copyReservationQueryCellInfo: function (args) {
      if (args.column.field === "resveTime") {
        if (
          args.data.timeStatus === "BLANK" ||
          args.data.timeStatus === "BLOCK" ||
          args.data.timeStatus === "HOLD"
        ) {
          args.cell.style.background = commonCodesGetColorValue(
            "TIME_STATUS",
            args.data.timeStatus
          );
          args.cell.style.color = "#FFFFFF";
        }
      } else if (args.column.field === "selfOpenFlag") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    courseDivClicked: function (courseIdx) {
      this.courseActiveIndex = courseIdx;
    },
    gridSelected: function (args, courseIdx) {
      // TODO : 체크 시 이전 timeId 홀딩 해제, 새 timeId 홀딩 등록
      this.timeSelected = args.data;

      // 다른 그리드 selection 해제
      const courseLength = this.reservationPossibleTimeCourseLists.length;
      for (
        let loopCourseIdx = 0;
        loopCourseIdx < courseLength;
        loopCourseIdx++
      ) {
        if (loopCourseIdx !== courseIdx) {
          this.$refs.copyReservationGrid[loopCourseIdx].clearSelection();
        }
      }
    },
  },
};
</script>
