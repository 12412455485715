import request from "@/utils/request";

/**
 * @return Promise
 */
export function getWaitingReservation(
  waitingId,
  bsnCode,
  memberName,
  memberNameNumber,
  memberNo,
  rceptStatus,
  waitingDateFrom,
  waitingDateTo
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/wait-reservation",
    method: "get",
    params: {
      waitingId: waitingId,
      bsnCode: bsnCode,
      memberName: memberName,
      memberNameNumber: memberNameNumber,
      memberNo: memberNo,
      rceptStatus: rceptStatus,
      waitingDateFrom: waitingDateFrom,
      waitingDateTo: waitingDateTo,
    },
  });
}

/**
 * @return Promise
 */
export function getReservationWaitCount(waitingDate, memberName) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/wait-reservation/count",
    method: "get",
    params: {
      waitingDate: waitingDate,
      memberName: memberName,
    },
  });
}

/**
 * @return Promise
 */
export function getReservationWaitTimeList(resveDate, resveCourse) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/wait-reservation/time-list",
    method: "get",
    params: {
      resveDate: resveDate,
      resveCourse: resveCourse,
    },
  });
}

/**
 * @return Promise
 */
export function saveWaitingReservation(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/wait-reservation",
    method: "put",
    data,
  });
}

/**
 * @return Promise
 */
export function saveReservationWaitCancel(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/wait-reservation-cancel",
    method: "put",
    data,
  });
}
