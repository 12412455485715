<template>
  <div>
    <ejs-dialog
      ref="reservationMovePopup"
      :header="'예약 이동'"
      :allowDragging="true"
      :showCloseIcon="true"
      :width="popupDialogWidth"
      height="800"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onReservationMovePopupClosed"
    >
      <div class="window reservationMove">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field promotionName">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          v-model="moveReservationDateRange"
                          type="lookup-condition"
                          @change="search"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    @click.native="search"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-box">
                      <ul>
                        <li>
                          예약일자 :
                          {{ getDayOfWeekAndCaption(popupData.resveDate) }}
                        </li>
                        <li>예약시간 : {{ popupData.resveTime }}</li>
                        <li>
                          예약코스 :
                          {{
                            commonCodesGetComName(
                              "COURSE_CODE",
                              popupData.resveCourse
                            )
                          }}
                        </li>
                      </ul>
                      <ul>
                        <li>예약자명 : {{ popupData.resveName }}</li>
                        <li>단체명 : {{ popupData.grpName }}</li>
                      </ul>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div
                    class="section-body"
                    :class="reservationCourseSectionBodyClassName"
                  >
                    <ejs-grid-wrapper
                      v-for="(
                        timeTableCourse, timeTableCourseIdx
                      ) in reservationPossibleTimeCourseLists"
                      :key="timeTableCourse.resveCourseCode"
                      @click.native="courseDivClicked(timeTableCourseIdx)"
                      ref="moveReservationGrid"
                      :style="widthBox"
                      :provides="moveReservationGridOptions().provides"
                      :columns="
                        moveReservationGridOptions().columns(
                          timeTableCourse.resveCourseName
                        )
                      "
                      :dataSource="timeTableCourse.reservationPossibleTimeLists"
                      :allowResizing="true"
                      :isSelectedRowRetain="false"
                      @queryCellInfo="moveReservationQueryCellInfo"
                      @headerCellInfo="moveReservationHeaderCellInfo"
                      @rowSelected="gridSelected($event, timeTableCourseIdx)"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="move keyColor">
              <erp-button
                button-div="SAVE"
                @click.native="saveReservationMovePopup()"
              >
                예약 이동
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeReservationMovePopup()">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import Vue from "vue";
import moment from "moment";
import { Edit, Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";

import { getDayOfWeekAndCaption, getDayOfWeekCaption } from "@/utils/date";
import {
  commonCodesGetColorValue,
  commonCodesGetComName,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetStandardInfo, commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import { getReservationPossibleTimeList } from "@/api/reservation";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { reservationCourseGridSectionBodyClassName } from "@/utils/gridUtil";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "ReservationMovePopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    InputDateRange,
    ErpButton,
  },
  data: function () {
    return {
      popupDialogWidth: null,
      popupData: {},
      timeSelected: {}, // 선택한 time object
      moveOption: {
        resveDateFrom: null,
        resveDateTo: null,
      },
      reservationPossibleTimeCourseLists: [],
      courseActiveIndex: -1,
      numberTemplate: function () {
        return {
          template: Vue.component("editOption", {
            template: "<span>{{parseInt(data.index) + 1}}</span>",
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
      courseDivColumns: [
        {
          field: "resveDate",
          allowEditing: false,
          headerText: "일자",
          type: "string",
          minWidth: "16",
          width: "90",
          textAlign: "center",
        },
        {
          field: "dwName",
          allowEditing: false,
          headerText: "요일",
          type: "string",
          minWidth: "16",
          textAlign: "center",
        },
        {
          field: "partDiv",
          allowEditing: false,
          headerText: "부",
          type: "string",
          minWidth: "16",
          width: "60",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "PART_DIV",
        },
        {
          field: "resveTime",
          allowEditing: false,
          headerText: "시간",
          type: "string",
          minWidth: "16",
          width: "90",
          textAlign: "center",
        },
      ],
    };
  },
  computed: {
    moveReservationDateRange: {
      get: function () {
        return {
          from: this.moveOption.resveDateFrom,
          to: this.moveOption.resveDateTo,
        };
      },
      set: function (moveReservationDateRange) {
        this.moveOption.resveDateFrom = moveReservationDateRange.from;
        this.moveOption.resveDateTo = moveReservationDateRange.to;
      },
    },
    widthBox(){
      return `width:calc((100%/${this.reservationPossibleTimeCourseLists.length}) + 2px) !important`;
    },
    reservationCourseSectionBodyClassName() {
      return reservationCourseGridSectionBodyClassName(
        this.reservationPossibleTimeCourseLists,
        this.$t("className.reservation.reservationCourseNumPrefix")
      );
    },
  },
  created: function () {
    const courseCodes = commonCodesGetCommonCode("COURSE_CODE", true);

    this.popupDialogWidth = Math.round(393.3 * courseCodes.length);
  },
  methods: {
    getDayOfWeekAndCaption: getDayOfWeekAndCaption,
    commonCodesGetComName: commonCodesGetComName,
    moveReservationGridOptions() {
      const courseDivColumns = JSON.parse(
        JSON.stringify(this.courseDivColumns)
      );
      return {
        provides: [Edit, Resize, ForeignKey],
        columns: (reserveCourseName) => [
          {
            headerText: `${reserveCourseName}코스`,
            allowEditing: true,
            columns: courseDivColumns,
            textAlign: "center",
          },
        ],
      };
    },
    search: function () {
      // TODO : 예약 가능 타임 리스트 조회 API 호출
      console.log(JSON.stringify(this.moveOption, null, 2));
      this.timeSelected = {}; // 선택한 time 초기화
      this.getTimeInfos();
    },
    getTimeInfos: function () {
      getReservationPossibleTimeList(
        this.moveOption.resveDateFrom,
        this.moveOption.resveDateTo
      )
        .then((response) => {
          this.reservationPossibleTimeCourseLists =
            response.value.reservationPossibleTimeCourseLists;
        })
        .catch((error) => {
          console.log("getReservationPossibleTimeList.err.===>", error);
        });
    },
    moveReservationHeaderCellInfo(args) {
      if (args.cell.column.headerText === "NO") {
        args.node.classList.add(this.$t("className.grid.noSortFilter"));
      }
    },
    onReservationMovePopupClosed: function () {
      this.$emit("popupClosed");
    },
    popupOpen: async function (popupData, resveDateFrom, resveDateTo) {
      console.log(JSON.stringify(popupData, null, 2));
      this.popupData = popupData;

      // 날짜 설정
      this.moveOption.resveDateFrom = resveDateFrom;
      this.moveOption.resveDateTo = resveDateTo;

      // 조회 후 popup open
      this.getTimeInfos();
      this.$refs.reservationMovePopup.show();
    },
    closeReservationMovePopup: function () {
      this.$refs.reservationMovePopup.hide();
    },
    saveReservationMovePopup: async function () {
      if (
        this.timeSelected.timeId === null ||
        this.timeSelected.timeId === undefined
      ) {
        this.errorToast("이동하실 시간을 선택해 주십시오");
      } else {
        // 예약 이동 API 호출
        if (!(await this.confirm("이동하시겠습니까?"))) {
          return;
        }

        const reservationMoveInfo = {
          moveTimeId: this.timeSelected.timeId,
          moveOptFlag: this.timeSelected.optFlag,
          moveResveDate: this.timeSelected.resveDate,
          existTimeId: this.popupData.timeId,
          existOptFlag: this.popupData.optFlag,
          existResveDate: this.popupData.resveDate,
        };

        await GolfErpAPI.reservationTimeMove(reservationMoveInfo);

        if (await this.confirm("예약 문자를 전송하시겠습니까?")) {
          // 예약 이동후 SMS 자동 전송.
          await this.autoSendSms();
        }

        this.$emit("popupClosed", { isReload: true, time: this.timeSelected });
        this.$refs.reservationMovePopup.hide();
      }
    },
    async autoSendSms() {
      let smsSendList = [];
      let sendData = {};
      let cmpnSendData = [];

      sendData.sendKey = this.popupData.resveNo;
      sendData.resveDate = this.popupData.resveDate;
      sendData.dwName = getDayOfWeekCaption(
        moment(this.popupData.resveDate).toDate()
      );
      sendData.resveCourse = this.popupData.resveCourse;
      sendData.resveTime = this.popupData.resveTime;
      sendData.toResveDate = this.timeSelected.resveDate;
      sendData.toResveCourse = this.timeSelected.resveCourse;
      sendData.toResveTime = this.timeSelected.resveTime;
      sendData.toDwName = this.timeSelected.dwName;
      sendData.transFlag = false;

      if (this.popupData.smsRecptnDiv === "A") {
        if (this.popupData.resveContactTel) {
          sendData.receiverName = this.popupData.resveName;
          sendData.name =
            this.popupData.resveName +
            " " +
            commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
              "MEMBER_DIV",
              this.popupData.resveMemberDiv,
              1
            );
          sendData.contactTel = this.popupData.resveContactTel;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }

        if (this.popupData.resveMngrHp) {
          sendData.receiverName = this.popupData.resveName;
          sendData.name =
            this.popupData.resveName +
            " " +
            commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
              "MEMBER_DIV",
              this.popupData.resveMemberDiv,
              1
            );
          sendData.contactTel = this.popupData.resveMngrHp;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      } else if (this.popupData.smsRecptnDiv === "B") {
        if (this.popupData.resveMngrHp) {
          sendData.receiverName = this.popupData.resveName;
          sendData.name =
            this.popupData.resveName +
            " " +
            commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
              "MEMBER_DIV",
              this.popupData.resveMemberDiv,
              1
            );
          sendData.contactTel = this.popupData.resveMngrHp;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      } else {
        if (this.popupData.resveContactTel) {
          sendData.receiverName = this.popupData.resveName;
          sendData.name =
            this.popupData.resveName +
            " " +
            commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
              "MEMBER_DIV",
              this.popupData.resveMemberDiv,
              1
            );
          sendData.contactTel = this.popupData.resveContactTel;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      }

      if (
        this.popupData.transName !== null &&
        this.popupData.transName !== undefined &&
        this.popupData.transName.trim() !== ""
      ) {
        if (this.popupData.transContactTel) {
          sendData.receiverName = this.popupData.transName;
          sendData.name =
            this.popupData.transName +
            " " +
            commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
              "MEMBER_DIV",
              this.popupData.transMemberDiv,
              1
            );
          sendData.contactTel = this.popupData.transContactTel;
          sendData.transFlag = true;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      }

      if (Array.isArray(this.popupData.cmpnContactTel) && this.popupData.cmpnContactTel.length > 0) {
        this.popupData.cmpnContactTel.forEach((tel) => {
          let cmpnSendData = JSON.parse(JSON.stringify(sendData));
          cmpnSendData.receiverName = this.popupData.resveName;
          cmpnSendData.name =
              this.popupData.resveName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                  "MEMBER_DIV",
                  this.popupData.resveMemberDiv,
                  1
              );
          cmpnSendData.contactTel = tel;
          smsSendList.push(cmpnSendData);
        });
        console.log("smsSendList:", smsSendList);
      }

      if (!(smsSendList.length > 0)) {
        return;
      }

      const smsSendInfo = {
        type: "G_CHANGE",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };

      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
        (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
        (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
        (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
          message +
          (smsSendInfo.bizName != null ? "\n" + smsSendInfo.bizName : "");
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let smsRequestList = [];
      smsSendList.forEach((data) => {
        let smsInfo = {
          type: smsSendInfo.type,
          kakaoFlag: smsSendInfo.kakaoSendFlag,
          smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
          kakaoDispatchKey: smsSendInfo.kakaoSendFlag
            ? smsSendInfo.kakaoDispatchKey
            : null,
          kakaoTemplateKey: smsSendInfo.kakaoSendFlag
            ? smsSendInfo.kakaoTemplateCode
            : null,
          key: data.sendKey,
          reservedFlag: false,
          reserveDateTime: null,
          receiverName: data.receiverName,
          receiverNumber: data.contactTel,
          subject: smsSendInfo.subject,
          message: message,
          replaceDataMap: {
            name: data.name,
            resveDate: data.resveDate,
            dwName: data.dwName,
            resveCourse: commonCodesGetComName("COURSE_CODE", data.resveCourse),
            resveTime: data.resveTime,
            resveCourseTime: data.resveCourseTime,
            resveFromTime: data.resveTime,
            resveToTime: data.toResveTime,
            resveFromCourse: commonCodesGetComName("COURSE_CODE", data.resveCourse),
            resveToCourse: commonCodesGetComName("COURSE_CODE", data.toResveCourse),
            resveFromDate: data.resveDate,
            resveToDate: data.toResveDate,
            fromDwName: data.dwName,
            toDwName: data.toDwName,
            transResveDiv: data.transFlag === true ? "위임예약" : "예약",
            headerMemo: smsSendInfo.bizNameLocation === "UP" ? (smsSendInfo.bizName ? smsSendInfo.bizName : null) : null
          },
          dispatchNumber: smsSendType.dsptchNo,
          sender: "ERP",
        };

        smsRequestList.push(smsInfo);
      });

      await GolfErpAPI.sendSMS(smsRequestList);
    },
    moveReservationQueryCellInfo: function (args) {
      if (args.column.field === "resveTime") {
        if (
          args.data.timeStatus === "BLANK" ||
          args.data.timeStatus === "BLOCK" ||
          args.data.timeStatus === "HOLD"
        ) {
          args.cell.style.background = commonCodesGetColorValue(
            "TIME_STATUS",
            args.data.timeStatus
          );
          args.cell.style.color = "#FFFFFF";
        }
      } else if (args.column.field === "selfOpenFlag") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    courseDivClicked: function (courseIdx) {
      this.courseActiveIndex = courseIdx;
    },
    gridSelected: function (args, courseIdx) {
      // TODO : 체크 시 이전 timeId 홀딩 해제, 새 timeId 홀딩 등록
      this.timeSelected = args.data;

      // 다른 그리드 selection 해제
      const courseLength = this.reservationPossibleTimeCourseLists.length;
      for (
        let loopCourseIdx = 0;
        loopCourseIdx < courseLength;
        loopCourseIdx++
      ) {
        if (loopCourseIdx !== courseIdx) {
          this.$refs.moveReservationGrid[loopCourseIdx].clearSelection();
        }
      }
    },
  },
};
</script>
