import { render, staticRenderFns } from "./HoldTimeViewPopup.vue?vue&type=template&id=22a5f823&scoped=true&"
import script from "./HoldTimeViewPopup.vue?vue&type=script&lang=js&"
export * from "./HoldTimeViewPopup.vue?vue&type=script&lang=js&"
import style0 from "./HoldTimeViewPopup.vue?vue&type=style&index=0&id=22a5f823&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a5f823",
  null
  
)

export default component.exports