<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="holdTimeViewPopup"
      header="홀딩사유 조회"
      width="422"
      :animationSettings="{ effect: 'None' }"
      allowDragging="true"
      showCloseIcon="true"
      isModal="false"
      @close="onHoldTimeViewPopupClosed"
    >
      <div class="window prePaymentCardInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">홀딩 사유 조회</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">예약일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ holdTimeInfo.resveDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">예약시간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ holdTimeInfo.resveTime }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">예약코스</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{
                                      commonCodesGetComName(
                                        "COURSE_CODE",
                                        holdTimeInfo.resveCourse
                                      )
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field remarks">
                              <!-- 필수 : required -->
                              <div class="title">홀딩사유</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    <div class="form">
                                      <input-textarea
                                        v-model="holdTimeInfo.holdResn"
                                        :disabled="!holdTimeInfo.holdResn"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">홀딩자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ holdTimeInfo.insertName }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">홀딩일시</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ holdTimeInfo.insertDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li
              v-if="holdTimeInfo.holdResn"
              class="save keyColor"
            >
              <erp-button button-div="SAVE" @click.native="onSaveButtonClicked">
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeHoldTimeViewPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .body-data .data-content .item.button,
body .body-data .data-content .item.check,
body .body-data .data-content .item.text {width: 100%;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import { commonCodesGetComName } from "@/utils/commonCodes";
import { getHoldTimeInfo } from "@/api/reservation";

import InputTextarea from "@/components/common/text/InputTextarea";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "HoldTimeViewPopup",
  components: { InputTextarea, ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  watch: {},
  async created() {},
  mounted() {},
  data() {
    return {
      orgHoldTimeInfo: null,
      holdTimeInfo: {
        timeId: null,
        resveDate: null,
        resveTime: null,
        resveCourse: null,
        holdResn: null,
        insertId: null,
        insertDt: null,
        insertName: null,
      },
      isSaved: false,
    };
  },
  methods: {
    commonCodesGetComName: commonCodesGetComName,
    onHoldTimeViewPopupClosed() {
      this.$emit("popupClosed", { isSaved: this.isSaved });
    },
    closeHoldTimeViewPopup() {
      this.$refs.holdTimeViewPopup.hide();
    },
    showHoldTimeViewPopup(popupData) {
      console.log("popupData.===>", popupData);

      this.getHoldTimeInfo(popupData);
    },
    async getHoldTimeInfo(timeId) {
      await getHoldTimeInfo(timeId)
        .then((response) => {
          this.holdTimeInfo = response.value;
          this.orgHoldTimeInfo = JSON.parse(JSON.stringify(this.holdTimeInfo));
        })
        .catch((error) => {
          console.log("getHoldTimeInfo.err.===>", error);
        });
    },
    async onSaveButtonClicked() {
      if (!this.holdTimeInfo.timeId) {
        this.errorToast("홀딩 정보가 존재하지 않습니다");
        return;
      }

      if (JSON.stringify(this.orgHoldTimeInfo.holdResn) === JSON.stringify(this.holdTimeInfo.holdResn)) {
        this.errorToast("홀딩사유가 수정되지 않았습니다");
        return;
      }

      try {
        await GolfErpAPI.reservationTimeHoldResnModify(this.holdTimeInfo.timeId, this.holdTimeInfo.holdResn);

        this.isSaved = true;

        await this.getHoldTimeInfo(this.holdTimeInfo.timeId);

        this.infoToast("저장되었습니다");
      } catch (e) {
        if (e instanceof Error) {
          console.error(e);
        } else {
          this.errorToast("홀딩사유 수정 중 오류가 발생하였습니다");
        }
      }
    },
  },
};
</script>
